import React, { useState }  from 'react';
import { gridClasses,DataGrid,jaJP } from '@mui/x-data-grid';
import { Box, Typography } from "@mui/material";

const MyDataGridY = ({ rows,bin,httItem,
  yday1, 
  yday2, 
  yday3, 
  yday4, 
  yday5, 
  yday6, 
  yday7, 
  ydayD, 
  ydayN, 
  ysday1, 
  ysday2, 
  ysday3, 
  ysday4, 
  ysday5, 
  ysday6, 
  ysday7, 
  ysHday1,
  ysHday2,
  ysHday3,
  ysHday4,
  ysHday5,
  ysHday6,
  ysHday7,
  ydayD_add,
  ydayN_add,
 }) => {
  console.log('DataGrid rendered');

  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});


  // const columns = React.useMemo(() => [
  const columns /*: GridColDef[]*/ = [

    {
      field: 'field0',
      headerName: '',
      headerAlign: 'center',
      align:'center',
      width: 72,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{(params.value)}</Typography>
        </div>
      )
    },
    {
      field: 'field1',
      headerName: yday1,
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   // <div>
      //     {/* <Box ><img src={(params.value)} alt="Logo" width="20" height="20" /></Box> */}
      //     {/* <Typography>{(params.value)}</Typography> */}
      //   // </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field2',
      headerName: yday2,
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      padding: '0px',
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field3',
      headerName: yday3,
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field4',
      headerName: yday4,
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field5',
      headerName: yday5,
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field6',
      headerName: yday6,
      type: 'number',
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },
    {
      field: 'field7',
      headerName: yday7,
      type: 'number',
      headerAlign: 'center',
      align:'center',
      width: 106,               // 2024/06/27 H.I
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      // renderCell: (params) => (
      //   <div>
      //     <Typography>{(params.value)}</Typography>
      //   </div>
      // )
      renderCell: (params) => imgCellY(params)
    },    
  // ], []);
  ];

      // imgCellY（予定用）
      const imgCellY = (params) => {
        // console.log(params);// ここでログ出すとループする
        let clr = '#ffffff';
        let clr2 = '#FFFFFF';
        let yyyy = String(httItem.NHNYMD).substring(0,4);
        let mm = String(httItem.NHNYMD).substring(4,6)-1;
        let dd = String(httItem.NHNYMD).substring(6,8);
        let dH = new Date(yyyy,mm,dd);
        // let dH = new Date('2024','10','3');
        let day = dH.getMonth() + 1;
        let cflg = 0;
        day = day + '/' + dH.getDate();
        // console.log('予定:'+params.colDef.headerName);
        
        if (params.field.includes(ydayD_add)) //土曜日
          {
            clr = '#DCE6F1';
            clr2 = '#DCE6F1';
            cflg = 2;
          }
        if (params.field.includes(ydayN_add)) //日曜日
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 3;
          }
          if (params.field.includes(ysday1)) //1日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 1;
          }
        if (params.field.includes(ysday2)) //2日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 2;
          }
        if (params.field.includes(ysday3)) //3日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 3;
          }
        if (params.field.includes(ysday4)) //4日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 4;
          }
        if (params.field.includes(ysday5)) //5日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 5;
          }
        if (params.field.includes(ysday6)) //6日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 6;
          }
        if (params.field.includes(ysday7)) //7日目
          {
            clr = '#FFCCFF';
            clr2 = '#FFCCFF';
            cflg = 7;
          }
        if (params.colDef.headerName.includes(day))
          {
            clr = '#FFFF00';
            cflg = 1;
          }
    
        const style = {
          backgroundColor: `${clr}`,
          width: '100%', // セル全体を覆う
          height: '100%', // セル全体の高さを指定
          display: 'flex', // Flexboxを使用
          alignItems: 'center', // 垂直方向の中心に配置
          justifyContent: 'center', // 水平方向の中心に配置
          fontSize: '1.4em',
          padding:'0px'
        };
        const style2 = {
          backgroundColor: `${clr2}`,
          width: '100%', // セル全体を覆う
          height: '100%', // セル全体の高さを指定
          display: 'flex', // Flexboxを使用
          alignItems: 'center', // 垂直方向の中心に配置
          justifyContent: 'center', // 水平方向の中心に配置
          fontSize: '1.4em',
          padding:'0px'
        };
        if (clr === '')
          {
            if (params.row.id === '1')
            {
              // １行目ならimg表示
              //S SK-0013-003 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
              // return (
              //   <div>
              //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
              //   </div>
              // )
              let resArray = params.value.split(",");
              if (resArray[1] === undefined){
                return (
                  <div>
                    <Box >
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
                    </Box>
                  </div>
                )
              }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
                return (
                  <div>
                    <Box >
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                    </Box>
                  </div>
                )
              }else{
                return (
                  <div>
                    <Box >
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                      <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                    </Box>
                  </div>
                )
              }    
              //E SK-0013-003
            } else {
              // １行目以外なら
              return (
                <div>
                  <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                </div>
              )
            }
          }else
          {
            if (cflg === 1)   //納品日対応
              {
                if (params.row.id === '1')
                  {
                    //S SK-0013-004 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
                    // return (
                    //   <div style={style2}>
                    //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
                    //   </div>
                    // )
                    let resArray = params.value.split(",");
                    if (resArray[1] === undefined){
                      return (
                        <div style={style2}>
                          <Box >
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
                          </Box>
                        </div>
                      )
                    }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
                      return (
                        <div style={style2}>
                          <Box >
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                          </Box>
                        </div>
                      )
                    }else{
                      return (
                        <div style={style2}>
                          <Box >
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                          </Box>
                        </div>
                      )
                    }                
                    //E SK-0013-004
                  }
                if (params.row.id == parseInt(bin)+3)
                  {
                    return (
                      <div style={style}>
                        <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                      </div>
                    )
                  }else{
                    return (
                      <div style={style2}>
                        <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                      </div>
                    )
    
                  }
              }else
              {
                if (params.row.id === '1')
                  {
                    // １行目ならimg表示
                    //S SK-0013-005 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
                    // return (
                    //   <div style={style} >
                    //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
                    //   </div>
                    // )
                    let resArray = params.value.split(",");
                    if (resArray[1] === undefined){
                      return (
                        <div style={style}>
                          <Box >
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
                          </Box>
                        </div>
                      )
                    }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
                      return (
                        <div style={style}>
                          <Box >
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                          </Box>
                        </div>
                      )
                    }else{
                      return (
                        <div style={style}>
                          <Box >
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                            <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
                          </Box>
                        </div>
                      )
                    }                
                    //E SK-0013-005
                  } else {
                    // １行目以外なら
                    return (
                      <div  style={style}>
                        <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
                      </div>
                    )
                  }    
              }
            }
      };

    //罫線表示（Data Grid）
    const gridStyle = {
      transform: 'scale(0.7)',
      // 他のスタイルプロパティをここに追加することもできます
      grid: {
        // x-data-gridの全体的な縮小
        '& .MuiDataGrid-root': {
          transform: 'scale(0.9)',
        },
        '.MuiDataGrid-cell':{
          border: 'solid 1px rgba(0, 0, 0, 1) !important',
          padding: '0',
        },
        '.MuiDataGrid-columnHeader':{
          padding: '0',
        },
        '.MuiDataGrid-toolbarContainer': {
          borderBottom: 'solid 1px rgba(0, 0, 0, 1)'  // ついでにツールバーの下に罫線を引く
        },
        '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
          borderRight: 'solid 1px rgba(0, 0, 0, 1) !important',
          padding: '0',
        },
        // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
        // 列ヘッダの最終列の右端に罫線を表示する
        '.MuiDataGrid-columnHeadersInner': {
          border: 'solid 1px rgba(0, 0, 0, 1) !important',
          transform: 'scale(0.9)',
        },
        '.MuiDataGrid-columnSeparator--sideRight':{
          visibility: 'show',
        },
        '.MuiDataGrid-columnHeaders':{
          height:'40px',
          border: 'solid 1px rgba(0, 0, 0, 1) !important',
          fontSize:'1.2em',
          padding: '0',
          // backgroundColor: '#65b2c6', // 2024/03/28追加
          [`& .MuiDataGrid-columnHeader[aria-label="${ydayD}"]`]: {
            backgroundColor: '#DCE6F1', // field6列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ydayN}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday1}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday2}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday3}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday4}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday5}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday6}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
          [`& .MuiDataGrid-columnHeader[aria-label="${ysHday7}"]`]: {
            backgroundColor: '#FFCCFF', // field7列のみ緑色
          },
        },
        // 削除済明細は背景色をグレーとする
        '.MuiDataGrid-row.row-deactive': {
          background: '#888888',
        },
        // 選択状態を黄色にする 2023/12/04 add imai
        '.MuiDataGrid-row.Mui-selected':{
          background: '#ffff00',
          '&:hover': {
            background: '#ffff00',       // ホバー時のバックグランドカラー
          },
        },
        [`.${gridClasses.cell}.cold`]: {
          backgroundColor: '#b9d5ff91',
          // color: '#1a3e72',
        },
        [`.${gridClasses.cell}.hot`]: {
          backgroundColor: '#ff943975',
          // color: '#1a3e72',
        },      
      }
    };
  
  
  return (
      <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 295, width: '818px',padding:'0px',ml:'-171px',mt:'-70px' }}>
            <DataGrid
              sx={gridStyle.grid }     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 6,
                  },
                },
              }}
              pageSizeOptions={[6]}
              // checkboxSelection
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              // getRowClassName={(params) => {
              //   if (params.row.DELFLG === '1') {
              //     // 削除済明細にクラスを設定する
              //     return 'row-deactive'
              //   }
              //   return '';
              // }}
              // getCellClassName={(params) => {       // ★背景色 上手くいかない 後で確認する
              //   if (params.field === 'field0' || params.value ===  undefined || params.value ===  ''
              //   || params.id === '1'|| params.id === '2') {
              //     return '';
              //   }
              //   // console.log(params.colDef.headerName)
              //   return params.value >= 25 ? 'hot' : 'cold';
              //   // return params.colDef.headerName.indexOf('日') ? 'hot' : 'cold';
              // }}              
              //E SK-0099-010
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                const delRows = diffRows.filter(x => x.DELFLG == '1');
                if (delRows.length > 0) {
                  // 削除済明細の場合はクリックされた際に選択状態にしない
                  return;
                }
                setSelectionModel(RowIds);
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              hideFooter
            />
      </Box>
  );
};

export default React.memo(MyDataGridY);