import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CustomButton from './CustomButton';

const TxtTable = React.memo(({ title, cell, MT }) => {
  // console.log('ButtonTable rendered');

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '0.7rem'
      },
      fontSize: '1.0rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (
        <Table sx={{border:0,maxWidth:200,mt:MT}} aria-label="spanning table" size="small">
          <TableBody>
            <TableRow>
              <TableCell align="center" bgcolor="lightgray" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)} width="60px">
                {title}
              </TableCell>
              <TableCell align="center" sx={Object.assign({}, styles.cssRecordFontSize, styles.cssRecordBorder)}                   width="60px">
                {cell}
              </TableCell>
            </TableRow>
          </TableBody>
      </Table>

  );
});

export default TxtTable;
