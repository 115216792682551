import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import { NumericFormat } from 'react-number-format';  //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

//モーダルウィンドウ関係
import CustomFormTCSH0110 from './CustomFormTCSH0110';

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0030 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [ksacde, setKsacde] = useState('');
  const [kmise, setKmise] = useState('');
  const [ksanm, setKsanm] = useState('');
  const [misenm, setMisenm] = useState('');
  const [alert, setAlert] = useState('');
  const [K_KSACDE,setK_KSACD] = useState('');
  const [K_MISE,setK_MISE] = useState('');
  const [lK_FLG,setlK_FLG] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)

  const inKSACDE = useRef(null);
  const inMISE = useRef(null);

  const rowData ={
    id : 0,                        // 行№
    KSACDE : state.KSACDES,        // 会社コード
    MISE : state.MISES,            // 店コード
    // BMNCDE : state.BMNCDE,         // 部門コード
    BMNCDE:state.BMNCDE === '1289' ? '' :
          state.BMNCDE === '3599' ? '' : state.BMNCDE,
    SHNID : '',                     // 商品ID
  }
  const [rowItem,setRowItem] = useState(rowData);

  // シノプス情報表示↓↓↓
  const [jdhttst, setJdhttst] = useState('-');  // 自動発注状態
  const [jdhttstcd, setJdhttstcd] = useState('');  // 自動発注状態コード
  const [shnrank, setShnrank] = useState('-');  // 商品ランク
  const [kjn_zai, setKjn_zai] = useState('-');  // 基準在庫
  const [lowhttsuu, setLowhttsuu] = useState('-');  // 最低発注数
  const [cal_zai, setCal_zai] = useState('-');  // 計算在庫
  const [text1, setText1] = useState('');
  const [text, setText] = useState('');
  const [text2, setText2] = useState('');
  const [text3, setText3] = useState('');
  const [text4, setText4] = useState('');
  // ↑親コンポーネントで使う：textの初期値とtextを更新する関数を宣言
  // ↓子コンポーネントから受け取った値で親コンポーネントのtextを更新する関数A
  const handleValueChange01 = async(newValue) => {
    console.log(' 親コンポーネントで受け取った値：',newValue)
    setText1(newValue);
  };
  const handleValueChange04 = async(newValue) => {
    setText(newValue);
  };
  const handleValueChange05 = (newValue) => {
    setText2(newValue);
  };
  const handleValueChange06 = (newValue) => {
    setText3(newValue);
  };
  const handleValueChange07 = (newValue) => {
    setText4(newValue);
  };
  //S SK-0093-003 2024/10/23           TCI)M.K【内部検証：バグFIX３】
  const handleValueChange15 = (newValue) => {
    console.log(' 親コンポーネントで受け取った値SHNID:',newValue)
    rowItem.SHNID = newValue;
  };
  //E SK-0093-003
  const [isSinopsInfoVisible, setSinopsInfoVisible] = useState(false);
  // シノプス入力画面を表示
  const showSinopsInfo = () => {
    window.glAlertDialogFlg = true;
    setSinopsInfoVisible(true);
  };
  // シノプス入力画面を閉じる
  const closeSinopsInfo = () => {
    window.glAlertDialogFlg = false;
    setSinopsInfoVisible(false);
  };
  // シノプス情報を登録する
  const regSinopsInfo = async() => {
    // チェック
    // let W_cal_zai = ''
    // if (Number(text4) >= 0){
    //   W_cal_zai = '+' + ('0000' + text4).slice(-5);
    // }else{
    //   W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
    // }
    let W_jdhttst = ''
    let W_kjn_zai = ''
    let W_lowhttsuu = ''
    let W_cal_zai = ''
    console.log('1:',text,'2:',text2,'3:',text3,'4:',text4)
    //S SK-0038-006 2024/7/4           TCI)M.K【課題No.72：自動発注不正データ発生の為、SQL是正】
    if (text2 === '-' || text3 === '-' || text4 === '-'){
      console.log('★不正データ検知');
      return;
    }
    //E SK-0038-006
    if (text === ''){   // 変更無しの場合
      W_jdhttst = jdhttstcd;
    }else{
      W_jdhttst = text;
    }

    if (text2 === ''){   // 変更無しの場合
      // W_kjn_zai = ('0000' + kjn_zai).slice(-5);
      W_kjn_zai = kjn_zai;
    }else{
      // W_kjn_zai = ('0000' + text2).slice(-5);
      W_kjn_zai = text2;
    }

    if (text3 === ''){   // 変更無しの場合
      // W_lowhttsuu = ('0000' + lowhttsuu).slice(-5);
      W_lowhttsuu = lowhttsuu;
    }else{
      // W_lowhttsuu = ('0000' + text3).slice(-5);
      W_lowhttsuu = text3;
    }

    // if (text4 === ''){   // 変更無しの場合
    //   if (Number(cal_zai) >= 0){
    //     W_cal_zai = '+' + ('0000' + cal_zai).slice(-5);
    //   }else{
    //     W_cal_zai = '-' + ('0000' + (Number(cal_zai)*-1)).slice(-5);
    //   }
    // }else{
    //   if (Number(text4) >= 0){
    //     W_cal_zai = '+' + ('0000' + text4).slice(-5);
    //   }else{
    //     W_cal_zai = '-' + ('0000' + (Number(text4)*-1)).slice(-5);
    //   }
    // }
    if (text4 === ''){   // 変更無しの場合
      W_cal_zai = cal_zai;
    }else{
      W_cal_zai = text4;
    }

    // データの更新
    // const response = await fetch('/TCSH0100/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
    //   body: JSON.stringify({
    //     header:rowItem,
    //     jdhttst:text,
    //     kjn_zai:('0000' + text2).slice(-5),
    //     lowhttsuu:('0000' + text3).slice(-5),
    //     cal_zai:W_cal_zai,
    //     Jancde:text1,
    // })});
    const response = await fetch('/TCSH0100/UpdJDHDat',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        header:rowItem,
        jdhttst:W_jdhttst,
        kjn_zai:W_kjn_zai,
        lowhttsuu:W_lowhttsuu,
        cal_zai:W_cal_zai,
        Jancde:text1,
        //S SK-0038-002 2024/7/4           TCI)M.K【課題No.72：自動発注不正データ発生の為、SQL是正】
        add_prg_id:'TCSH0030',
        //E SK-0038-002
        JGNCDE:state.JGNCDE,
        DELFLG:'0',
      })});
    if(response.res === '-1') {
      console.log('送信失敗');
    }else{
      switch(text)
      {
        case '01':
          setJdhttst('対象');
          break;
        case '02':
          setJdhttst('対象外');
          break;
        case '03':
          setJdhttst('店舗停止');
          break;
        case '04':
          setJdhttst('本部停止');
          break;
        default:
          break;
      };
      setKjn_zai(text2);
      setLowhttsuu(text3);
      setCal_zai(text4);
    }
    //S SK-0092-001 2024/10/23           TCI)M.K【課題No.115：在庫修正後画面を閉じない】
    // // 閉じる
    // window.glAlertDialogFlg = false;
    // setSinopsInfoVisible(false);
    //E SK-0092-001
  };
  // シノプス情報表示ここまで↑↑↑

  // シノプスボタン押下時の処理
  const onClick_FooterButton_sinops = async() => {
    let errflg = 0;

    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: lK_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG
      });
      showSinopsInfo();
    }
  }
  
  //S SK-0075-007 2024/9/20           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】
  const handlebeforeunload = (event) => {
    // デフォルトの 'beforeunload' イベントをキャンセル
    event.preventDefault();  // 正しい記述
    event.returnValue = '';  // 必須: これでブラウザに確認ダイアログが表示されます
    console.log('beforeunload イベントが発火しました');
  };
  
  useEffect(() => {
    // 'beforeunload' のイベントリスナーを登録
    window.addEventListener('beforeunload', handlebeforeunload);
  
    // クリーンアップ関数（イベントリスナーを削除）
    return () => {
      window.removeEventListener('beforeunload', handlebeforeunload);
    };
  }, []);  // handlebeforeunload が依存していないため、依存配列は空にして問題ありません 
  //E SK-0075-007

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setKsacde(event.target.value);
    switch(event.target.value)
    {
      case '001':
        setKsanm('オークワ');
        break;
      case '003':
        setKsanm('オーク');
        break;
      case '005':
        setKsanm('パーティハウス');
        break;
      case '008':
        setKsanm('オー・エンターテイメント');
        break;
      default:
        break;
    };
    
    inMISE.current.focus();
    inMISE.current.select();
  };

  // 初期処理
  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
  },[]);

  useEffect(() => {
  }, []);

  // ボタン２押下時の処理
  const onClick_FooterButton_Next2 = async() => {
    let errflg = 0;

    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: lK_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG
      });
      navigate('/TCSH0130');
    }
  }

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    navigate('/TCSH0020');
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        let errflg = 0;
        setAlert('　');

        if (errflg == 0)
        {
          inputNxRef.current.focus();   //次の項目に遷移
        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <CustomFormTCSH0110 
        isVisible={isSinopsInfoVisible} 
        message01={''}                    // スキャンコード
        message03={''}                    // 商品名
        message04={''}                    // 自動発注状態
        message05={''}                    // 最低陳列量
        message06={''}                    // 最低発注数
        message07={''}                    // 店舗在庫
        message08={''}                    // ランク
        callergamen={'TCSH0030'}          // 呼び出し元画面
        onClose={closeSinopsInfo} 
        // onClear={clearSinopsInfo} 
        onReg={regSinopsInfo} 
        handleValueChange01={handleValueChange01}
        handleValueChange04={handleValueChange04}
        handleValueChange05={handleValueChange05}
        handleValueChange06={handleValueChange06}
        handleValueChange07={handleValueChange07}
        //S SK-0093-004 2024/10/23           TCI)M.K【内部検証：バグFIX３】
        handleValueChange15={handleValueChange15}
        //E SK-0093-004
        />      
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px' }}>
            {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Box width={'55%'} sx={{  ...commonStyles, borderRadius: '5px',overflow: 'hidden' }}><img src={`${process.env.PUBLIC_URL}/OKUWA_Logo.svg`} alt="Logo" width="40" height="40" /></Box>
        </Grid>

        <br/>
        <Box >
            <Button
              type="submit" 
              color="success" 
              variant="contained" 
              fullWidth
              onClick={(e) => onClick_FooterButton_sinops()}
              >
              {'自動発注メンテナンス'}
            </Button>
          </Box>
        <br/>
        <Box >
            <Button
              type="submit" 
              color="success" 
              variant="contained" 
              fullWidth
              onClick={(e) => onClick_FooterButton_Next2()}
              >
              {'要チェック一覧'}
            </Button>
          </Box>
        <br /><br />
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:50,mr:'200px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>&nbsp;
          </Box >
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0030;