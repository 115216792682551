import React, { useState }  from 'react';
import { gridClasses,DataGrid,jaJP } from '@mui/x-data-grid';
import { Box, Typography } from "@mui/material";

const MyDataGridJ = ({ rows2,jbtnname2,
  jday1,    
  jday2,    
  jday3,    
  jday4,    
  jday5,    
  jday6,    
  jday7,    
  jsHday1,  
  jsHday2,  
  jsHday3,  
  jsHday4,  
  jsHday5,  
  jsHday6,  
  jsHday7,  
  jsday1,   
  jsday2,   
  jsday3,   
  jsday4,   
  jsday5,   
  jsday6,   
  jsday7,   
  jdayD,    
  jdayN,    
  jdayD_add,
  jdayN_add,
}) => {
  console.log('DataGrid rendered');

  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});


  // const columns = React.useMemo(() => [
    const columns2 /*: GridColDef[]*/ = [
      // {
      //   field: 'detailAction',
      //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
      //   align: 'left',
      //   width: 60,
      //   type: 'actions',
      //   // getActions: getDetailAction,
      //   description: '詳細画面を表示',  // ツールチップで表示される説明
      //   hideable: false,          // 非表示にできないようにする
      //   sortable: false,          // ソートできないようにする
      //   hideSortIcons: false,     // ソートアイコンを表示しない
      //   resizable: false,         // リサイズできないようにする
      //   disableExport: false,     // エクスポートに含めないようにする
      //   disableReorder: false,    // 並べ替えできないようにする
      //   filterable: false,        // フィルタリングしないようにする
      //   disableColumnMenu: true  // カラムメニューを表示しない
      // } ,
  
      //IDは必ず必要みたい
      // {
      //   field: 'id',
      //   headerName: 'No.',
      //   align:'center',
      //   width: 50 ,
      //   editable: false ,         //編集可能:false
      //   sortable: false,          // ソートできないようにする
      //   disableColumnMenu: true,   // カラムメニューを表示しない
      // },
      {
        field: 'field0',
        headerName: jbtnname2,
        headerAlign: 'center',
        align:'center',
        width: 72 ,
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        renderCell: (params) => (
          <div>
            <Typography>{(params.value)}</Typography>
          </div>
        )
      },
      {
        field: 'field1',
        headerName: jday1,
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
      {
        field: 'field2',
        headerName: jday2,
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
      {
        field: 'field3',
        headerName: jday3,
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
      {
        field: 'field4',
        headerName: jday4,
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
      {
        field: 'field5',
        headerName: jday5,
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
      {
        field: 'field6',
        headerName: jday6,
        type: 'number',
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
      {
        field: 'field7',
        headerName: jday7,
        type: 'number',
        headerAlign: 'center',
        align:'center',
        width: 106 ,               // 2024/06/27 H.I
        editable: false ,         //編集可能:false
        sortable: false,          // ソートできないようにする
        disableColumnMenu: true,   // カラムメニューを表示しない
        // renderCell: (params) => (
        //   <div>
        //     <Typography>{(params.value)}</Typography>
        //   </div>
        // )
        renderCell: (params) => imgCellJ(params)
      },
    ];
  // ], []);

  const imgCellJ = (params) => {
    // console.log(params);// ここでログ出すとループする
    let clr = '#ffffff';
    let clr2 = '#FFFFFF';
    let cflg = 0;
    // console.log('予定:'+params.colDef.headerName);
    
    if (params.field.includes(jdayD_add)) //土曜日
      {
        clr = '#DCE6F1';
        clr2 = '#DCE6F1';
        cflg = 2;
      }
    if (params.field.includes(jdayN_add)) //日曜日
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
      if (params.field.includes(jsday1)) //1日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 1;
      }
    if (params.field.includes(jsday2)) //2日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 2;
      }
    if (params.field.includes(jsday3)) //3日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 3;
      }
    if (params.field.includes(jsday4)) //4日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 4;
      }
    if (params.field.includes(jsday5)) //5日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 5;
      }
    if (params.field.includes(jsday6)) //6日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 6;
      }
    if (params.field.includes(jsday7)) //7日目
      {
        clr = '#FFCCFF';
        clr2 = '#FFCCFF';
        cflg = 7;
      }

    const style = {
      backgroundColor: `${clr}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize: '1.4em',
      padding:'0px'
    };
    const style2 = {
      backgroundColor: `${clr2}`,
      width: '100%', // セル全体を覆う
      height: '100%', // セル全体の高さを指定
      display: 'flex', // Flexboxを使用
      alignItems: 'center', // 垂直方向の中心に配置
      justifyContent: 'center', // 水平方向の中心に配置
      fontSize: '1.4em',
      padding:'0px'
    };
    if (params.row.id === '1')
      {
        // １行目ならimg表示
        //S SK-0013-005 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
        // return (
        //   <div style={style} >
        //     <Box ><img src={(`${process.env.PUBLIC_URL}`+params.value)} alt="Logo" width="30" height="30" /></Box>
        //   </div>
        // )
        let resArray = params.value.split(",");
        if (resArray[1] === undefined){
          return (
            <div style={style}>
              <Box >
                <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo" width="30px" height="30px" />
              </Box>
            </div>
          )
        }else if (resArray[1] === '/tenki_icon/tokidoki.gif'){
          return (
            <div style={style}>
              <Box >
                <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='3.6px' height='30px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
              </Box>
            </div>
          )
        }else{
          return (
            <div style={style}>
              <Box >
                <img src={(`${process.env.PUBLIC_URL}`+resArray[0])} alt="Logo"  width='30px' height='30px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[1])} alt="Logo"  width='10.8px' height='9.6px' />
                <img src={(`${process.env.PUBLIC_URL}`+resArray[2])} alt="Logo"  width='30px' height='30px' />
              </Box>
            </div>
          )
        }                
        //E SK-0013-005
    } else if(params.row.id === '3') {  // 2024/06/27 H.I
      // １行目以外なら
      return (
        <div  style={style}>
          <Typography style={{fontSize:'1.2em'}}>{(params.value)}</Typography>
        </div>
      )
    } else {
      // １行目以外なら
      return (
        <div  style={style}>
          <Typography style={{fontSize:'1.4em'}}>{(params.value)}</Typography>
        </div>
      )
    }              
  };

  //罫線表示（Data Grid）
  const gridStyle2 = {
    transform: 'scale(0.7)',
    // 他のスタイルプロパティをここに追加することもできます
    grid: {
      // x-data-gridの全体的な縮小
      '& .MuiDataGrid-root': {
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-cell':{
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        padding: '0',
      },
      '.MuiDataGrid-columnHeader':{
        padding: '0',
      },
      '.MuiDataGrid-toolbarContainer': {
        borderBottom: 'solid 1px rgba(0, 0, 0, 1)'  // ついでにツールバーの下に罫線を引く
      },
      '.MuiDataGrid-row .MuiDataGrid-cell:not(:last-child)': {
        borderRight: 'solid 1px rgba(0, 0, 0, 1) !important'
      },
      // 2022/06/01 追記 列ヘッダの最終セルの右側に罫線が表示されなくなった・・・
      // 列ヘッダの最終列の右端に罫線を表示する
      '.MuiDataGrid-columnHeadersInner': {
        border: 'solid 1px rgba(0, 0, 0, 1) !important',
        transform: 'scale(0.9)',
      },
      '.MuiDataGrid-columnSeparator--sideRight':{
        visibility: 'show',
      },
      '.MuiDataGrid-columnHeaders':{
        height:'40px',
        fontSize:'1.2em',
        // backgroundColor: '#65b2c6', // 2024/03/28追加
        // '& .MuiDataGrid-columnHeader[data-field="field6"]': {
        //   backgroundColor: '#DCE6F1', // field6列のみ緑色
        // },
        // '& .MuiDataGrid-columnHeader[data-field="field7"]': {
        //   backgroundColor: '#FFCCFF', // field7列のみ緑色
        // },
        // backgroundColor: '#65b2c6', // 2024/03/28追加
        [`& .MuiDataGrid-columnHeader[aria-label="${jdayD}"]`]: {
          backgroundColor: '#DCE6F1', // field6列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jdayN}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday1}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday2}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday3}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday4}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday5}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday6}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
        [`& .MuiDataGrid-columnHeader[aria-label="${jsHday7}"]`]: {
          backgroundColor: '#FFCCFF', // field7列のみ緑色
        },
      },
      // 削除済明細は背景色をグレーとする
      '.MuiDataGrid-row.row-deactive': {
        background: '#888888',
      },
      // 選択状態を黄色にする 2023/12/04 add imai
      '.MuiDataGrid-row.Mui-selected':{
        background: '#ffff00',
        '&:hover': {
          background: '#ffff00',       // ホバー時のバックグランドカラー
        },
      },
      [`.${gridClasses.cell}.cold`]: {
        backgroundColor: '#b9d5ff91',
        // color: '#1a3e72',
      },
      [`.${gridClasses.cell}.hot`]: {
        backgroundColor: '#ff943975',
        // color: '#1a3e72',
      },      
    }
  };

  return (
    <Box className="dataGridList" style={gridStyle2} sx={{ display: 'flex', justifyContent: 'flex-start',height: 365, width: '818px',padding:'0px',ml:'-171px',mt:'-85px' }}>
    <DataGrid
      sx={gridStyle2.grid }     // --> 不具合対応のためcssで罫線を引く
      rows={rows2}
      columns={columns2}
      density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
      initialState={{
        pagination: {
          paginationModel: {
            pageSize: 8,
          },
        },
      }}
      pageSizeOptions={[8]}
      // checkboxSelection
      disableRowSelectionOnClick
      localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
      showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
      showCellRightBorder                                               // セルの右側に線を引く
      getRowClassName={(params) => {
        if (params.row.DELFLG === '1') {
          // 削除済明細にクラスを設定する
          return 'row-deactive'
        }
        return '';
      }}
      rowSelectionModel={selectionModel}
      onRowSelectionModelChange={(RowIds) => {
        const diffRows = rows2.filter(x =>RowIds.indexOf(x.id) != -1);
        const delRows = diffRows.filter(x => x.DELFLG == '1');
        if (delRows.length > 0) {
          // 削除済明細の場合はクリックされた際に選択状態にしない
          return;
        }
        setSelectionModel(RowIds);
      }}
      paginationModel={pagenationModel}
      onPaginationModelChange={(model) => {
        console.log(model);
        setPagenationModel(model);
      }}
      hideFooter
      />
  </Box>
  );
};

export default React.memo(MyDataGridJ);