import React from 'react';
import { Oval } from 'react-loader-spinner';
import './FullPageSpinner.css'; // カスタムCSSファイルをインポート

const FullPageSpinner = () => {
  return (
    <div className="spinner-container">
      <Oval
        height={100}
        width={100}
        color="#4fa94d"
        ariaLabel="oval-loading"
        secondaryColor="#4fa94d"
        strokeWidth={2}
        strokeWidthSecondary={2}
      />
    </div>
  );
};

export default FullPageSpinner;