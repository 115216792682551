/**
 * フォーカス処理
 * @param e 処理対処
 * @param time 遅延時間(未設定の場合100)
 */
export function onMyFocus (e,time = 100){
  setTimeout(() =>{
    // console.log('選択中項目id:%o',document.activeElement.id);
    // console.log('処理対処id:%o',e.target.id);
    if(document.activeElement.id === e.target.id){
      e.target.select();
    }
  },time)
}

/**
 * フォーカス処理
 * @param e 処理対処
 * @param time 遅延時間(未設定の場合100)
 */
export function onNextFocus (e,time = 100){
  setTimeout(() =>{
    //console.log('選択中項目id:%o',document.activeElement.id);
    //console.log('処理対処id:%o',e.current.id);
    if(document.activeElement.id === e.current.id){
      e.current.select();
    }
  },time)
}


export default {onMyFocus,onNextFocus};