import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import { PatternFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0040 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [httkbn, setHttkbn] = useState('1');
  const [httymd, setHttymd] =  useState('');
  const [alert, setAlert] = useState('発注日・発注区分を設定して下さい。');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)

  const keyDisabled = useRef(false);
  const inHTTYMD = useRef(null);
  const inHTTKBN = useRef(null);
  const buttonRef = useRef(null);                 // 次へボタン遷移用
  const [movefocusflg,setMove] = useState();      // 次へボタン遷移制御用
  const [fkhttkbn, setFkHttkbn] = useState('0');  // 次へボタン遷移制御用

  //項目のバックカラー制御用
  const [inHttymdclr,setHttymdclr] = useState('#FFFFFF');
  const [inHttkbnclr,setHttkbnclr] = useState('#FFFFFF');

  //disabled制御用
  const [disHttkbn, setDisHttkbn] = useState(false);    // 発注区分

  //S SK-0075-008 2024/9/20           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】
  const handlebeforeunload = (event) => {
    // デフォルトの 'beforeunload' イベントをキャンセル
    event.preventDefault();  // 正しい記述
    event.returnValue = '';  // 必須: これでブラウザに確認ダイアログが表示されます
    console.log('beforeunload イベントが発火しました');
  };
  
  useEffect(() => {
    // 'beforeunload' のイベントリスナーを登録
    window.addEventListener('beforeunload', handlebeforeunload);
  
    // クリーンアップ関数（イベントリスナーを削除）
    return () => {
      window.removeEventListener('beforeunload', handlebeforeunload);
    };
  }, []);  // handlebeforeunload が依存していないため、依存配列は空にして問題ありません 
  //E SK-0075-008

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setHttkbn(event.target.value);
    console.log('選択発注区分:%o',event.target.value)
    switch(event.target.value)
    {
      case '1':
        break;
      case '2':
        break;
      default:
        break;
    };
  };

  const clearSelect = () => {
    if (movefocusflg != 1) {
      setFkHttkbn(inHTTKBN.current.value);
    }
    setMove(0);
  }

  useEffect(() => {
    buttonRef.current.focus();
    setFkHttkbn('0');
  }, [httkbn,fkhttkbn]);

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }
    
    let d = new Date(state.SDAY);
    // let d = new Date('2023/12/15'); // テスト用
    // let d = new Date('2024/02/29'); // テスト用
    // let d = new Date('2023/08/30'); // テスト用
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);
    
    month = (d.getMonth() + 1).toString().padStart(2, '0');
    day = d.getDate().toString().padStart(2, '0');
    setHttymd(year + '/' + month + '/' + day);
    inHTTYMD.current.focus();    //フォーカス
    setTimeout(() => {inHTTYMD.current.select();}, 100);   //フォーカス
    //コンボボックス初期値移送
    console.log('httkbn初期値前:'+httkbn)
    // setHttkbn(inHTTKBN.target.value);
    console.log('httkbn初期値後:'+httkbn)

    //コンボボックス有効・無効切替
    // if(state.IRYOFLG == '0' && state.BMNCDE != '1120')
    if(state.IRYOFLG === '0')
    {
      setDisHttkbn(false)
      setHttkbnclr('#FFFFFF')
    }else{
      setDisHttkbn(true)
      setHttkbnclr('#CCCCCC')       //グレー
    }
    

  },[]);

  useEffect(() => {
  }, []);

  // 次へボタン押下時の処理
  const onClick_FooterButton_Next = async() => {
    if (keyDisabled.current)
    {
      console.log('Enter2回押されました')
      return;
    }
    keyDisabled.current = true;

    let errflg = 0;
    console.log('次へ:%o',state)
    errflg = await Ymd_Check()
    console.log('エラーフラグ:%o',errflg)
    if (errflg == 0 )
    {
      dispatch({ type: 'Update' , GAMEN: state.GAMEN,KSACDE: state.KSACDE, MISE: state.MISE,
        KSACDES : state.KSACDES,MISES: state.MISES,K_KSACDES : state.K_KSACDE,K_MISES: state.K_MISE,
        MISEMEI: state.MISEMEI,JGNCDE: state.JGNCDE,JGNMEIKNJ: state.JGNMEIKNJ,KENGEN: state.KENGEN,
        SMISEFLG: state.SMISEFLG,SMISEJH:state.SMISEJH,K_FLG: state.K_FLG,SDAY: state.SDAY,BUTTON:state.BUTTON, 
        BMNCDE:state.BMNCDE,BRUMEI:state.BRUMEI,GRPCDE:state.GRPCDE,IRYOFLG:state.IRYOFLG,HTTYMD:httymd,HTTKBN:httkbn
      });

      // ログ出力
      const responseUA1 = await fetch('/TCSH0000/SH_LOG_DAT_INS',{method: 'POST',headers: {'Content-Type': 'application/json'},
        body: JSON.stringify({
        ksacde : state.KSACDES      //会社コード
        ,mise :  state.MISES        //店コード
        ,biko : state.BMNCDE + ',発注開始,' + httymd + ',' + httkbn   //備考
        ,add_jgncde : state.JGNCDE  //ユーザーID
        ,add_prg_id : 'TCSH0040'    //プログラムID
        ,delflg :'0'                //削除フラグ
      })});
      const dataUA1 = await responseUA1.json();
      console.log('ログインデータ:%o',dataUA1.res);

      // 伝票ヘッダ 登録エラー
      if (dataUA1.res == -1) {
        setAlert('ログインデータ登録エラー');
          return
      }

      if (state.GAMEN == 0)
      { 
        console.log('スキャン発注開く')
        if (state.IRYOFLG === '0'){
          navigate('/TCSH0100');
        }else if (state.IRYOFLG === '1'){
          navigate('/TCSH0101');
        }
      }else{
        if (state.IRYOFLG === '0'){
          navigate('/TCSH0200');
        }else if (state.IRYOFLG === '1'){
          navigate('/TCSH0201');
        }
      }
    }
    else{
      console.log('元の項目にカーソル移動３')
      inHTTYMD.current.focus();   //次の項目に遷移        
    }

    setTimeout(() => {
      // 処理中フラグを下げる
      keyDisabled.current = false;
    }, 1000);
  
}

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    if (state.IRYOFLG==='0')          //食品・住居
    {
      navigate('/TCSH0020');
    }else if(state.IRYOFLG==='1')     //衣料
    {
      navigate('/TCSH0021');
    }else{
      navigate('/');
    }
  }

  // 日付チェック処理
  const Ymd_Check = async() => {
    console.log('日付チェック処理 httymd:'+httymd)
    setAlert('');
    let errflg = 0;

    //日付妥当性チェック
    if (ckDate(httymd.substring(0, 4) + '/' + httymd.substring(4, 6) + '/' + httymd.substring(6,8))===false){
      errflg = -1;
      setHttymdclr('#f59d9d');
      setAlert('日付入力エラー');
      return 1;
    }

    // システム日 ＜ 入力日（西暦） ＜ システム日＋６ヶ月
    let indate = new Date(httymd.substring(0, 4) + '/' + httymd.substring(4, 6) + '/' + httymd.substring(6,8));
    let mindate = new Date(state.SDAY);
    // let mindate = new Date('2023/12/15'); // テスト用
    // let mindate = new Date('2024/02/29'); // テスト用うるう年
    // let mindate = new Date('2023/08/30'); // テスト用うるう年
    let maxdate = new Date(state.SDAY);
    // let maxdate = new Date('2023/12/15'); // テスト用
    // let maxdate = new Date('2024/02/29'); // テスト用うるう年
    // let maxdate = new Date('2023/08/30'); // テスト用うるう年
    maxdate.setMonth(maxdate.getMonth()+6);
    console.log('入力日付:'+indate)
    console.log('最小日付:'+mindate)
    if(indate < mindate || indate > maxdate){
      errflg = -1;
      setHttymdclr('#f59d9d');
      setAlert('入力された値は範囲外です。');
      return 1;
    }

    // if (errflg != 0)
    // {
    //   inHTTKBN.current.focus();   //次の項目に遷移
    //   inHTTYMD.current.focus();   //次の項目に遷移
    // }

    // 正常時
    setHttymdclr('#FFFFFF');
    return 0;
  }

  const ckDate = (strYYYYMMDD) =>{  // YYYY/MM/DD ⇒ 2023/09/07 等
    if(!strYYYYMMDD.match(/^\d{4}\/\d{2}\/\d{2}$/)){
        return false;
    }
    var y = strYYYYMMDD.split("/")[0];
    var m = strYYYYMMDD.split("/")[1] - 1;
    var d = strYYYYMMDD.split("/")[2];
    var date = new Date(y,m,d);
    if(date.getFullYear() != y || date.getMonth() != m || date.getDate() != d){
        return false;
    }
    return true;
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        let errflg = 0;
        let skipflg = 0;
        setAlert('　');
        if (inputRef.current.id == "httymd")
        {
          skipflg = 1;
          setMove(1);
          // console.log('Enter_httymd1:'+inputRef.current.defaultValue)
          // setHttymd(inputRef.current.defaultValue);
          // console.log('Enter_httymd2:'+httymd)
          errflg = await Ymd_Check();
        }
        if (inputRef.current.id == "mise")
        {
          console.log('mise:'+inputRef.current.defaultValue)
          //従業員マスタ店読み込み
          const responseC = await fetch('/TCSH0000/MR_WRKM_MST_sel',{method: 'POST',headers: {'Content-Type': 'application/json'},
            body: JSON.stringify({KSACDE: state.KSACDE,JGNCDE: state.JGNCDE,MISE:'',RCNT:0})});
          const dataC = await responseC.json();
          if (dataC.RCNT >= 1)
          {
            if ( state.MISE != '200' && state.MISE != '700')
            {
              var srcresult = dataC.res.find((v) => v.MISE == inputRef.current.defaultValue);
              if (typeof srcresult === "undefined")
              {
                setAlert('その店コードは処理できません。');
                inputRef.current.focus();
                errflg = -1;
              }
            }
          }
        }

        if (errflg == 0)
        {
          if (skipflg == 1 )
          {
            if (state.IRYOFLG==='1' || state.BMNCDE == '1120')
            {
              inputNxRef = buttonRef;
            }
          }
          console.log('次の項目にカーソル移動１')
          inputNxRef.current.focus();   //次の項目に遷移
        }
        else
        {
          console.log('次の項目にカーソル移動２')
          inputNxRef.current.focus();   //次の項目に遷移
          //buttonRef.current.focus();    //次へに遷移
          console.log('元の項目にカーソル移動２')
          inputRef.current.focus();   //次の項目に遷移        
        }
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  };  

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '1.2rem'
      },
      fontSize: '1.2rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (
    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "470px",
          width: "280px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:400,mr:'10px'}}>
              {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:110,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          {/* 画像の角丸対応 */}
          <Box sx={{  ...commonStyles, borderRadius: '5px',overflow: 'hidden' }}><img src={`${process.env.PUBLIC_URL}/OKUWA_Logo.svg`} alt="Logo" width="40" height="40" /></Box>
          {/* <Avatar sx={{ bgcolor: teal[400] }}>
          </Avatar> */}
          <Typography variant={"h6"} sx={{ m: "30px" }}>
          日付・区分選択
          </Typography>
        </Grid>

        <br/>
        <Stack direction="row" spacing={2}>
        <PatternFormat  //発注日
              id={'httymd'}
              inputRef={inHTTYMD}                   //項目名定義？
              format='####/##/##'
              mask={['Y','Y','Y','Y','M','M','D','D']}
              color='success'
              customInput={TextField}             //textfieldにreact-number-formatの機能を追加
              size='small'                        //textboxの全体サイズ
              label='発注日'                        //textboxの左上に表示するラベル
              variant='outlined'                  //枠の表示方法
              type='tel'                          //[PDA、Tablet対応]入力モード：tel
              inputMode='tel'                     //[PDA、Tablet対応]入力モード：tel
              // fixedDecimalScale={false}           //[PDA、Tablet対応]小数点On=true/Off=false
              // decimalScale={0}                    //[PDA、Tablet対応]少数部の桁[0]
              InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
              onValueChange={(values, sourceInfo) => {
                // const row = JSON.parse(JSON.stringify(rowItem));
                // row.JYOTOYMD = values.value;
                // row.JYOTOYMD2 = values.formattedValue;
                // setRowItem(row);
                setHttymd(values.value);
              }}
              //sx={{width: '13ch',background:inJYOTOYMDclr}}              //textboxの幅
              sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), {background:inHttymdclr})}  //2023/11/07 入力項目の表示内容拡大
              value={httymd}           //初期値
              // allowLeadingZeros                   //前ゼロ表示 on
              // disabled={disRow}                    //使用可／不可
              placeholder='YYYY/MM/DD'
              //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center'} }}  //補完,最大文字数,中央寄せ
              inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'center',touchAction: 'none', padding: styles.cssIputOutlinPadding} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
              onKeyDown={(e) => onKeyDown(e.key,inHTTYMD,inHTTYMD,inHTTKBN)}
              onFocus={(e) => e.target.select()}
              />
        </Stack>
        <br/>
        <Stack direction="row" spacing={2}>
          <FormControl size="small" sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8))}>
            <InputLabel id="select-label" color='success'>発注区分</InputLabel>
            <Select
                inputRef={inHTTKBN}
                color='success'
                defaultValue={'1'}
                labelId="select-label"
                id="select-label"
                className="cssInputOutlineJotKbn" 
                sx={Object.assign({}, cssInputOutlineSetwidth(styles.cssInputOutline, 8), styles.cssInputOutlineJotKbn, {background:inHttkbnclr})} 
                value={httkbn}
                label="発注区分"
                onChange={handleChange}
                onFocus={clearSelect}
                disabled={disHttkbn}                    //使用可／不可
                >
              <MenuItem value={'1'}>１：定番</MenuItem>
              <MenuItem value={'2'}>２：特売</MenuItem>
            </Select>
          </FormControl>
        </Stack>
        <br /><br />
        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:50,mr:'150px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              ref={buttonRef}
              variant='contained'
              color='success'
              sx={{width:50,mr:'5px'}}
              onClick={(e) => onClick_FooterButton_Next()}
              >
              {'次へ'}
            </Button>
          </Box>
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0040;
