// EOSコードとして使用（頭２桁）
export const myEOS = [
    '10',
    '15',
    '16',
    '17',
    '19',
    '20',
    '21',
    '22',
    '28',
    '30',
    '31',
    '32',
    '35',
    '36',
    '37',
    '38',
    '39',
    '40',
    '41',
    '50',
    '51',
    '52',
    '53',
    '60',
    '63',
    '68',
    '70',
    '76',
    '78',
    '80',
    '81',
    '82',
    '83',
    '84',
    '85',
    '86',
    '91',
    '92',
    '93',
    '94',
    '95',
]
