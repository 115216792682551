import React, { useEffect, useRef } from 'react';
import './CustomFormTCSH0120.css'; // スタイル用のCSSをインポート

function CustomFormTCSH0120(props) {
  const okButtonRef = useRef(null);
  const returnFocus = useRef(null);

  useEffect(() => {
    // アラートが表示されたときにOKボタンにフォーカスをセットする
    if (props.isVisible) {
      returnFocus.current = document.activeElement;
      okButtonRef.current.focus();
    } else {
      // アラートが非表示になったときに元の場所にフォーカスを戻す
      returnFocus.current && returnFocus.current.focus();
    }
  }, [props.isVisible]);

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      okButtonRef.current.focus();
    }
  };

  return (
    <div className={`vba-message-box-0120-overlay ${props.isVisible ? 'show' : 'hide'}`}>
      <div className={`vba-message-box-0120 ${props.isVisible ? 'show' : 'hide'}`}>
        <div className="vba-message-box-0120-content">
          <div className="vba-message-box-0120-text">
            {/* <p>{props.message}</p> */}
            <p className='p-title-0120'>商品情報</p>
            {/* <input type="text" name="name" placeholder={props.message} class="textbox"></input> */}
            <table class="design01-0120">
              <tr>
                <th>JANコード</th>
                <td>{props.message06}</td>
              </tr>
              <tr>
                <th>EOSコード</th>
                <td>{props.message01}</td>
                </tr>
              <tr>
                <th>仕入先コード</th>
                <td>{props.message07}</td>
              </tr>
              <tr>
                <th>仕入先名</th>
                <td>{props.message08}</td>
              </tr>
              <tr>
                <th>原単価</th>
                <td>{props.message09}</td>
              </tr>
              <tr>
                <th>売単価</th>
                <td>{props.message10}</td>
              </tr>
              <tr>
                <th>値入率</th>
                <td>{props.message11}</td>
                </tr>
              <tr>
                <th>発注単位</th>
                <td>{props.message12}</td>
              </tr>
              <tr>
                <th>入数</th>
                <td>{props.message13}</td>
              </tr>
              <tr>
                <th>賞味日数</th>
                <td>{props.message14}</td>
              </tr>
              <tr>
                <th>部門-AU-ﾗｲﾝ-ｸﾗｽ</th>
                <td>{props.message15}</td>
              </tr>
              <tr>
                <th>発注開始日</th>
                <td>{props.message16}</td>
              </tr>
              <tr>
                <th>発注終了日</th>
                <td>{props.message17}</td>
              </tr>
              <tr>
                <th>取引先品番</th>
                <td>{props.message02}</td>
              </tr>
              <tr>
                <th>カラー名</th>
                <td>{props.message03}</td>
              </tr>
              <tr>
                <th>サイズ名</th>
                <td>{props.message04}</td>
              </tr>
              <tr>
                <th>シーズン</th>
                <td>{props.message05}</td>
              </tr>
            </table>
          </div>
        </div>
        <div className="vba-message-box-0120-buttons" onKeyDown={handleKeyDown}>
          <button ref={okButtonRef} onClick={() => { props.onClose(); returnFocus.current && returnFocus.current.focus(); }}>OK</button>
        </div>
      </div>
    </div>
  );
}

export default CustomFormTCSH0120;