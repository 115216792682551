import React, { useEffect, useRef ,useState } from 'react';
import './CustomFormTCSH0210.css'; // スタイル用のCSSをインポート

function CustomFormTCSH0210(props) {
  const okButtonRef = useRef(null);
  const changeButtonRef = useRef(null);
  const returnFocus = useRef(null);

  // const [yojitu01, setYojitu01] = useState();         // ↓日付
  // const [yojitu02, setYojitu02] = useState();         // 
  // const [yojitu03, setYojitu03] = useState();         // 
  // const [yojitu04, setYojitu04] = useState();         // 
  // const [yojitu05, setYojitu05] = useState();         // 
  // const [yojitu06, setYojitu06] = useState();         // 
  // const [yojitu07, setYojitu07] = useState();         // 

  //S SK-0013-017 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
  // const [yojitu11, setYojitu11] = useState();         // ↓img
  // const [yojitu12, setYojitu12] = useState();         // 
  // const [yojitu13, setYojitu13] = useState();         // 
  // const [yojitu14, setYojitu14] = useState();         // 
  // const [yojitu15, setYojitu15] = useState();         // 
  // const [yojitu16, setYojitu16] = useState();         // 
  // const [yojitu17, setYojitu17] = useState();         // 
  const [yojitu11, setYojitu11] = useState([]);         // ↓img
  const [yojitu12, setYojitu12] = useState([]);         // 
  const [yojitu13, setYojitu13] = useState([]);         // 
  const [yojitu14, setYojitu14] = useState([]);         // 
  const [yojitu15, setYojitu15] = useState([]);         // 
  const [yojitu16, setYojitu16] = useState([]);         // 
  const [yojitu17, setYojitu17] = useState([]);         // 
  //E SK-0013-017
  const [yojitu21, setYojitu21] = useState();         // ↓体感
  const [yojitu22, setYojitu22] = useState();         // 
  const [yojitu23, setYojitu23] = useState();         // 
  const [yojitu24, setYojitu24] = useState();         // 
  const [yojitu25, setYojitu25] = useState();         // 
  const [yojitu26, setYojitu26] = useState();         // 
  const [yojitu27, setYojitu27] = useState();         // 
  const [yojitu31, setYojitu31] = useState();         // ↓企画
  const [yojitu32, setYojitu32] = useState();         // 
  const [yojitu33, setYojitu33] = useState();         // 
  const [yojitu34, setYojitu34] = useState();         // 
  const [yojitu35, setYojitu35] = useState();         // 
  const [yojitu36, setYojitu36] = useState();         // 
  const [yojitu37, setYojitu37] = useState();         // 
  const [yojitu41, setYojitu41] = useState();         // ↓販売
  const [yojitu42, setYojitu42] = useState();         // 
  const [yojitu43, setYojitu43] = useState();         // 
  const [yojitu44, setYojitu44] = useState();         // 
  const [yojitu45, setYojitu45] = useState();         // 
  const [yojitu46, setYojitu46] = useState();         // 
  const [yojitu47, setYojitu47] = useState();         // 
  const [yojitu51, setYojitu51] = useState();         // ↓入荷
  const [yojitu52, setYojitu52] = useState();         // 
  const [yojitu53, setYojitu53] = useState();         // 
  const [yojitu54, setYojitu54] = useState();         // 
  const [yojitu55, setYojitu55] = useState();         // 
  const [yojitu56, setYojitu56] = useState();         // 
  const [yojitu57, setYojitu57] = useState();         // 
  const [yojitu61, setYojitu61] = useState();         // ↓正販
  const [yojitu62, setYojitu62] = useState();         // 
  const [yojitu63, setYojitu63] = useState();         // 
  const [yojitu64, setYojitu64] = useState();         // 
  const [yojitu65, setYojitu65] = useState();         // 
  const [yojitu66, setYojitu66] = useState();         // 
  const [yojitu67, setYojitu67] = useState();         // 
  const [yojitu71, setYojitu71] = useState();         // ↓見切
  const [yojitu72, setYojitu72] = useState();         // 
  const [yojitu73, setYojitu73] = useState();         // 
  const [yojitu74, setYojitu74] = useState();         // 
  const [yojitu75, setYojitu75] = useState();         // 
  const [yojitu76, setYojitu76] = useState();         // 
  const [yojitu77, setYojitu77] = useState();         // 
  const [yojitu81, setYojitu81] = useState();         // ↓廃棄
  const [yojitu82, setYojitu82] = useState();         // 
  const [yojitu83, setYojitu83] = useState();         // 
  const [yojitu84, setYojitu84] = useState();         // 
  const [yojitu85, setYojitu85] = useState();         // 
  const [yojitu86, setYojitu86] = useState();         // 
  const [yojitu87, setYojitu87] = useState();         // 
  //S SK-0002-006 2024/6/13           TCI)M.K【最高気温・最低気温追加（PDA）】
  const [yojituH1, setYojituH1] = useState();         // ↓温度（最高）
  const [yojituH2, setYojituH2] = useState();         // 
  const [yojituH3, setYojituH3] = useState();         // 
  const [yojituH4, setYojituH4] = useState();         // 
  const [yojituH5, setYojituH5] = useState();         // 
  const [yojituH6, setYojituH6] = useState();         // 
  const [yojituH7, setYojituH7] = useState();         // 
  const [yojituL1, setYojituL1] = useState();         // ↓温度（最低）
  const [yojituL2, setYojituL2] = useState();         // 
  const [yojituL3, setYojituL3] = useState();         // 
  const [yojituL4, setYojituL4] = useState();         // 
  const [yojituL5, setYojituL5] = useState();         // 
  const [yojituL6, setYojituL6] = useState();         // 
  const [yojituL7, setYojituL7] = useState();         // 
  //E SK-0002-006
  const [jbtnname, setJBtnName] = useState('');       // ボタン名（先々週→今週→先週）
  let calendarHtml = ''                               // HTMLを組み立てる変数
  let calendarHtml3 = ''                              // HTMLを組み立てる変数3

  useEffect(() => {
    // アラートが表示されたときにOKボタンにフォーカスをセットする
    if (props.isVisible) {
      returnFocus.current = document.activeElement;
      okButtonRef.current.focus();
    } else {
      // アラートが非表示になったときに元の場所にフォーカスを戻す
      returnFocus.current && returnFocus.current.focus();
    }
  }, [props.isVisible]);

  useEffect(() => {
    // props受け取ったら分解する
    console.log('props.message01の変更を検知しました:',props.message01)
    for (let i = 0; i<props.message01.length; i++){
      switch (props.message01[i].id){
        case '0':
          setJBtnName(props.message01[i].field0);// ボタン名称
          // setYojitu01(props.message01[i].field1);
          // setYojitu02(props.message01[i].field2);
          // setYojitu03(props.message01[i].field3);
          // setYojitu04(props.message01[i].field4);
          // setYojitu05(props.message01[i].field5);
          // setYojitu06(props.message01[i].field6);
          // setYojitu07(props.message01[i].field7);
          // test
          calendarHtml += '<table>'
          calendarHtml += '<tr>' 
          if(props.message01[i].field1.includes('日') || props.message01[i].field1.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field1 + "</td>"
          }else if (props.message01[i].field1.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field1 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field1 + "</td>"
          }
          if(props.message01[i].field2.includes('日') || props.message01[i].field2.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field2 + "</td>"
          }else if (props.message01[i].field2.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field2 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field2 + "</td>"
          }
          if(props.message01[i].field3.includes('日') || props.message01[i].field3.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field3 + "</td>"
          }else if (props.message01[i].field3.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field3 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field3 + "</td>"
          }
          if(props.message01[i].field4.includes('日') || props.message01[i].field4.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field4 + "</td>"
          }else if (props.message01[i].field4.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field4 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field4 + "</td>"
          }
          if(props.message01[i].field5.includes('日') || props.message01[i].field5.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field5 + "</td>"
          }else if (props.message01[i].field5.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field5 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field5 + "</td>"
          }
          if(props.message01[i].field6.includes('日') || props.message01[i].field6.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field6 + "</td>"
          }else if (props.message01[i].field6.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field6 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field6 + "</td>"
          }
          if(props.message01[i].field7.includes('日') || props.message01[i].field7.includes('祝')){
            calendarHtml += "<td class='red-0210'>" + props.message01[i].field7 + "</td>"
          }else if (props.message01[i].field7.includes('土')){
            calendarHtml += "<td class='blue-0210'>" + props.message01[i].field7 + "</td>"
          }else{
            calendarHtml += "<td>" + props.message01[i].field7 + "</td>"
          }
          calendarHtml += '</tr>'
          calendarHtml += '</table>'
          document.querySelector('#calendar').innerHTML = calendarHtml
          // test
          break;
        case '1':
          //S SK-0013-018 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】
          // setYojitu11(props.message01[i].field1);
          // setYojitu12(props.message01[i].field2);
          // setYojitu13(props.message01[i].field3);
          // setYojitu14(props.message01[i].field4);
          // setYojitu15(props.message01[i].field5);
          // setYojitu16(props.message01[i].field6);
          // setYojitu17(props.message01[i].field7);
          setYojitu11(props.message01[i].field1.split(","));
          setYojitu12(props.message01[i].field2.split(","));
          setYojitu13(props.message01[i].field3.split(","));
          setYojitu14(props.message01[i].field4.split(","));
          setYojitu15(props.message01[i].field5.split(","));
          setYojitu16(props.message01[i].field6.split(","));
          setYojitu17(props.message01[i].field7.split(","));
          //E SK-0013-018
          break;
        case '2':
          setYojitu21(props.message01[i].field1);
          setYojitu22(props.message01[i].field2);
          setYojitu23(props.message01[i].field3);
          setYojitu24(props.message01[i].field4);
          setYojitu25(props.message01[i].field5);
          setYojitu26(props.message01[i].field6);
          setYojitu27(props.message01[i].field7);
          break;
        case '3':
          setYojitu31(props.message01[i].field1);
          setYojitu32(props.message01[i].field2);
          setYojitu33(props.message01[i].field3);
          setYojitu34(props.message01[i].field4);
          setYojitu35(props.message01[i].field5);
          setYojitu36(props.message01[i].field6);
          setYojitu37(props.message01[i].field7);
          break;
        case '4':
          setYojitu41(props.message01[i].field1);
          setYojitu42(props.message01[i].field2);
          setYojitu43(props.message01[i].field3);
          setYojitu44(props.message01[i].field4);
          setYojitu45(props.message01[i].field5);
          setYojitu46(props.message01[i].field6);
          setYojitu47(props.message01[i].field7);
          break;
        // case '5':
        //   setYojitu51(props.message01[i].field1);
        //   setYojitu52(props.message01[i].field2);
        //   setYojitu53(props.message01[i].field3);
        //   setYojitu54(props.message01[i].field4);
        //   setYojitu55(props.message01[i].field5);
        //   setYojitu56(props.message01[i].field6);
        //   setYojitu57(props.message01[i].field7);
        //   break;
        // case '6':
        //   setYojitu61(props.message01[i].field1);
        //   setYojitu62(props.message01[i].field2);
        //   setYojitu63(props.message01[i].field3);
        //   setYojitu64(props.message01[i].field4);
        //   setYojitu65(props.message01[i].field5);
        //   setYojitu66(props.message01[i].field6);
        //   setYojitu67(props.message01[i].field7);
        //   break;
        // case '7':
        //   setYojitu71(props.message01[i].field1);
        //   setYojitu72(props.message01[i].field2);
        //   setYojitu73(props.message01[i].field3);
        //   setYojitu74(props.message01[i].field4);
        //   setYojitu75(props.message01[i].field5);
        //   setYojitu76(props.message01[i].field6);
        //   setYojitu77(props.message01[i].field7);
        //   break;
        // case '8':
        //   setYojitu81(props.message01[i].field1);
        //   setYojitu82(props.message01[i].field2);
        //   setYojitu83(props.message01[i].field3);
        //   setYojitu84(props.message01[i].field4);
        //   setYojitu85(props.message01[i].field5);
        //   setYojitu86(props.message01[i].field6);
        //   setYojitu87(props.message01[i].field7);
        //   break;
        case '5':
          calendarHtml3 += '<table>'
          calendarHtml3 += '<tr>' 

          if(props.message01[i].field0 === '1'){
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else if(props.message01[i].field0 === '2'){
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else if(props.message01[i].field0 === '3'){
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else if(props.message01[i].field0 === '4'){
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else if(props.message01[i].field0 === '5'){
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else if(props.message01[i].field0 === '6'){
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else if(props.message01[i].field0 === '7'){
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td class='yellow-0210'>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }else{
            calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
            calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          }
          calendarHtml3 += '</tr>'
          break;
        case '6':
          calendarHtml3 += '<tr>' 
          calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          calendarHtml3 += '</tr>'
          break;
        case '7':
          calendarHtml3 += '<tr>' 
          calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          calendarHtml3 += '</tr>'
          break;
        case '8':
          calendarHtml3 += '<tr>' 
          calendarHtml3 += "<td>" + (props.message01[i].field1!==undefined ? props.message01[i].field1 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field2!==undefined ? props.message01[i].field2 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field3!==undefined ? props.message01[i].field3 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field4!==undefined ? props.message01[i].field4 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field5!==undefined ? props.message01[i].field5 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field6!==undefined ? props.message01[i].field6 : '')+ "</td>"
          calendarHtml3 += "<td>" + (props.message01[i].field7!==undefined ? props.message01[i].field7 : '')+ "</td>"
          calendarHtml3 += '</tr>'
          calendarHtml3 += '</table>'
          document.querySelector('#calendar3').innerHTML = calendarHtml3
          break;
        //S SK-0002-007 2024/6/13           TCI)M.K【最高気温・最低気温追加（PDA）】
        case '9':
          setYojituH1(props.message01[i].field1);
          setYojituH2(props.message01[i].field2);
          setYojituH3(props.message01[i].field3);
          setYojituH4(props.message01[i].field4);
          setYojituH5(props.message01[i].field5);
          setYojituH6(props.message01[i].field6);
          setYojituH7(props.message01[i].field7);
          break;
        case '10':
          setYojituL1(props.message01[i].field1);
          setYojituL2(props.message01[i].field2);
          setYojituL3(props.message01[i].field3);
          setYojituL4(props.message01[i].field4);
          setYojituL5(props.message01[i].field5);
          setYojituL6(props.message01[i].field6);
          setYojituL7(props.message01[i].field7);
          break;
        //E SK-0002-007
        default:
          break;          
      }
    }
  }, [props.message01]);

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      okButtonRef.current.focus();
    }
  };

  return (
    <div className={`vba-message-box-0210-overlay ${props.isVisible ? 'show' : 'hide'}`}>
      <div className={`vba-message-box-0210 ${props.isVisible ? 'show' : 'hide'}`}>
        <div className="vba-message-box-0210-content">
          <div className="vba-message-box-0210-text">
            <p className='p-title-0210'>予定/実績</p>
            <table class="design01-0210"  id="calendar">
            </table>            
            <table class="design01-0210">
              {/* <tr>
                <td>{yojitu01}</td>
                <td>{yojitu02}</td>
                <td>{yojitu03}</td>
                <td>{yojitu04}</td>
                <td>{yojitu05}</td>
                <td className="blue">{yojitu06}</td>
                <td className="red">{yojitu07}</td>
              </tr> */}
              <tr>
                {/* S SK-0013-016 2024/6/18           TCI)M.K【課題No.39：天気アイコン表示不具合対応】 */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu11)} alt="Logo" width="30" height="30" /></td> */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu12)} alt="Logo" width="30" height="30" /></td> */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu13)} alt="Logo" width="30" height="30" /></td> */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu14)} alt="Logo" width="30" height="30" /></td> */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu15)} alt="Logo" width="30" height="30" /></td> */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu16)} alt="Logo" width="30" height="30" /></td> */}
                {/* <td><img src={(`${process.env.PUBLIC_URL}`+yojitu17)} alt="Logo" width="30" height="30" /></td> */}
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu11[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu11[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu11[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu12[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu12[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu12[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu13[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu13[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu13[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu14[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu14[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu14[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu15[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu15[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu15[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu16[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu16[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu16[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                <td>
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu17[0])} alt="Logo" width='17px' height='17px' />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu17[1])} alt="Logo" width='3.6px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                  <img src={(`${process.env.PUBLIC_URL}`+yojitu17[2])} alt="Logo" width='17px' height='17px'
                    onError={e => {
                      // src属性に指定した画像のURLが404エラー等で取得できない場合、no photo用の画像を表示させる
                      e.target.onError = null; // 下記画像が取得できない場合の無限ループを防ぐため、nullを代入
                      e.target.src = `${process.env.PUBLIC_URL}`+'/tenki_icon/dummy.png';
                    }}
                  />
                </td>
                {/* E SK-0013-016 */}
              </tr>
              <tr>
                <td>{yojitu21}</td>
                <td>{yojitu22}</td>
                <td>{yojitu23}</td>
                <td>{yojitu24}</td>
                <td>{yojitu25}</td>
                <td>{yojitu26}</td>
                <td>{yojitu27}</td>
              </tr>
              {/* S SK-0002-009 2024/6/13           TCI)M.K【最高気温・最低気温追加（PDA）】 */}
              <tr class="text-red-0210">
                <td>{yojituH1}</td>
                <td>{yojituH2}</td>
                <td>{yojituH3}</td>
                <td>{yojituH4}</td>
                <td>{yojituH5}</td>
                <td>{yojituH6}</td>
                <td>{yojituH7}</td>
              </tr>
              <tr class="text-blue-0210">
                <td>{yojituL1}</td>
                <td>{yojituL2}</td>
                <td>{yojituL3}</td>
                <td>{yojituL4}</td>
                <td>{yojituL5}</td>
                <td>{yojituL6}</td>
                <td>{yojituL7}</td>
              </tr>
              {/* E SK-0002-009 */}
            </table>
            <p className='p-text-0210'>企画/販売</p>
            <table class="design01-0210">
              <tr>
                <td>{yojitu31}</td>
                <td>{yojitu32}</td>
                <td>{yojitu33}</td>
                <td>{yojitu34}</td>
                <td>{yojitu35}</td>
                <td>{yojitu36}</td>
                <td>{yojitu37}</td>
              </tr>
              <tr>
                <td>{yojitu41}</td>
                <td>{yojitu42}</td>
                <td>{yojitu43}</td>
                <td>{yojitu44}</td>
                <td>{yojitu45}</td>
                <td>{yojitu46}</td>
                <td>{yojitu47}</td>
              </tr>
            </table>
            <p className='p-text-0210'>入荷/正販/見切/廃棄</p>
            <table class="design01-0210"  id="calendar3">
            </table>            
            {/* <table class="design01-0210">
              <tr>
                <td>{yojitu51}</td>
                <td>{yojitu52}</td>
                <td>{yojitu53}</td>
                <td>{yojitu54}</td>
                <td>{yojitu55}</td>
                <td>{yojitu56}</td>
                <td>{yojitu57}</td>
              </tr>
              <tr>
                <td>{yojitu61}</td>
                <td>{yojitu62}</td>
                <td>{yojitu63}</td>
                <td>{yojitu64}</td>
                <td>{yojitu65}</td>
                <td>{yojitu66}</td>
                <td>{yojitu67}</td>
              </tr>
              <tr>
                <td>{yojitu71}</td>
                <td>{yojitu72}</td>
                <td>{yojitu73}</td>
                <td>{yojitu74}</td>
                <td>{yojitu75}</td>
                <td>{yojitu76}</td>
                <td>{yojitu77}</td>
              </tr>
              <tr>
                <td>{yojitu81}</td>
                <td>{yojitu82}</td>
                <td>{yojitu83}</td>
                <td>{yojitu84}</td>
                <td>{yojitu85}</td>
                <td>{yojitu86}</td>
                <td>{yojitu87}</td>
              </tr>
            </table> */}
          </div>
        </div>
        <div className="vba-message-box-0210-buttons" onKeyDown={handleKeyDown}>
          <button ref={okButtonRef} onClick={() => { props.onClose(); returnFocus.current && returnFocus.current.focus(); }}>OK</button>　
          <button ref={changeButtonRef} onClick={() => { 
            props.onChange(); returnFocus.current && returnFocus.current.focus(); 
          }}>{jbtnname}</button>
        </div>
      </div>
    </div>
  );
}

export default CustomFormTCSH0210;