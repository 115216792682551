import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import CustomButton from './CustomButton';

const ButtonTable2 = React.memo(({ 
  onButtonClick1, disBtn1, Btntxt1, width1, onButtonClick2, disBtn2, Btntxt2, width2 }) => {
  // console.log('ButtonTable rendered');
  return (
    <Table sx={{border:0,maxWidth:200}} aria-label="spanning table" size="small">
      <TableBody>
        <TableRow>
          <TableCell sx={{padding:'0px'}}>
            <CustomButton
              onClick={onButtonClick1}
              disabled={disBtn1}
              width={width1}
            >
              {Btntxt1}
            </CustomButton>
          </TableCell>
          <TableCell sx={{padding:'0px'}}>
            <CustomButton
              onClick={onButtonClick2}
              disabled={disBtn2}
              width={width2}
            >
              {Btntxt2}
            </CustomButton>
          </TableCell>
        </TableRow>
      </TableBody>
    </Table>
  );
});

export default ButtonTable2;
