import React from 'react';
import Typography from '@mui/material/Typography';

const CustomTypography = React.memo(({ variant, color, children, ...props }) => {
  console.log('CustomTypography rendered');
  return (
    <Typography variant={variant} color={color} {...props}>
      {children}
    </Typography>
  );
}, (prevProps, nextProps) => {
  // 再レンダリングを防ぐための比較関数
  return prevProps.variant === nextProps.variant &&
         prevProps.color === nextProps.color &&
         prevProps.children === nextProps.children;
});

export default CustomTypography;
