
import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
//S SK-0075-002 2024/9/19           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】
// import { BrowserRouter } from 'react-router-dom';
import { HashRouter } from 'react-router-dom';
//E SK-0075-002

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  //<React.StrictMode>
    //S SK-0075-003 2024/9/19           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】
    // <BrowserRouter>
    //   <App />
    // </BrowserRouter>
    <HashRouter>
      <App />
    </HashRouter>
    //E SK-0075-003
  //</React.StrictMode>
);
