import { createContext, useEffect, useState,useImperativeHandle,useRef } from 'react';

const TimeContext = createContext();

export const TimeProvider = ({ children }) => {
    const timeRef = useRef();
    const [time, setTime] = useState(new Date());

    useImperativeHandle(timeRef, () => ({
        reTimer,
    }));    

    useEffect(() => {
        // 最初にサーバから時刻を取得
        const fetchTime = async () => {
            try {
                const response = await fetch('/TCSH0101/TIME_sel', {
                    method: 'POST',
                    headers: {'Content-Type': 'application/json'},
                    body: JSON.stringify({RCNT: 0})
                });
                const data = await response.json();
                setTime(new Date(data.STIME)); // サーバから取得した時刻で時間をセット
                console.log('初回:'+data.STIME);
            } catch (error) {
                console.error('Failed to fetch time:', error);
                setTime(new Date()); // エラーがあればクライアントの現在時刻を使用
            }
        };

        fetchTime();

        // 以降は1秒ごとにローカルで時刻を更新
        const timerID = setInterval(() => {
            setTime(prevTime => new Date(prevTime.getTime() + 1000));
        }, 1000);
        
        return () => clearInterval(timerID);
    }, []);

    // 締切時間タイマー停止時
    const reTimer = async () => {
        console.log('タイマー再取得')
        try {
            const response = await fetch('/TCSH0101/TIME_sel', {
                method: 'POST',
                headers: {'Content-Type': 'application/json'},
                body: JSON.stringify({RCNT: 0})
            });
            const data = await response.json();
            setTime(new Date(data.STIME)); // サーバから取得した時刻で時間をセット
            console.log('再取得:'+data.STIME);
        } catch (error) {
            console.error('Failed to fetch time:', error);
            setTime(new Date()); // エラーがあればクライアントの現在時刻を使用
        }
    }

    const contextValue = {
        time,
        timeRef, // 追加項目
    };

    return (
        <TimeContext.Provider value={contextValue}>
            {children}
        </TimeContext.Provider>
    );
}

export default TimeContext;