import React, { useEffect, useRef } from 'react';
import './CustomDialog.css';

const CustomDialog = ({ isVisible, message, onOk, onCancel }) => {
  const okButtonRef = useRef(null);
  const cancelButtonRef = useRef(null);
  const returnFocus = useRef(null);

  useEffect(() => {
    // アラートが表示されたときにOKボタンにフォーカスをセットする
    if (isVisible) {
      console.log('表示：%o',isVisible);
      returnFocus.current = document.activeElement;
      // okButtonRef.current.focus();
      setTimeout(() =>{ okButtonRef.current.focus()},100);
      setTimeout(() =>{ okButtonRef.current.focus()},200);
      setTimeout(() =>{ okButtonRef.current.focus()},300);
      setTimeout(() =>{ okButtonRef.current.focus()},400);
      setTimeout(() =>{ okButtonRef.current.focus()},500);
      setTimeout(() =>{ okButtonRef.current.focus()},600);
      setTimeout(() =>{ okButtonRef.current.focus()},700);
      setTimeout(() =>{ okButtonRef.current.focus()},800);
      setTimeout(() =>{ okButtonRef.current.focus()},900);
      setTimeout(() =>{ okButtonRef.current.focus()},1000);
      if (typeof window !== 'undefined') {
        // ブラウザ環境でのみ実行されるコード
        window.focus();
      }
    } else {
      okButtonRef.current.classList.add('focus-visible');
      // アラートが非表示になったときに元の場所にフォーカスを戻す
      returnFocus.current && returnFocus.current.focus();
    }
  }, [isVisible]);

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      okButtonRef.current.classList.remove('focus-visible');

      /* キャンセルボタンありの判定
      if (e.shiftKey) {
        // Shift + Tabが押された場合、キャンセルボタンにフォーカスを移動
        if (document.activeElement === okButtonRef.current) {
          e.preventDefault();
          cancelButtonRef.current.focus();
        }
      } else {
        // Tabが押された場合、OKボタンにフォーカスを移動
        if (document.activeElement === cancelButtonRef.current) {
          e.preventDefault();
          okButtonRef.current.focus();
        }
      }
      */
      // Tabが押された場合、OKボタンにフォーカスを移動
      if (document.activeElement === cancelButtonRef.current) {
        e.preventDefault();
        okButtonRef.current.focus();
      }

    }
  };

   return (
    <div className={`custom-dialog ${isVisible ? 'show' : 'hide'}`}>
      <div className="dialog-content">
        <p>{message}</p>
        <div className="button" onKeyDown={handleKeyDown}>
          <button ref={okButtonRef} className="ok focus-visible" onClick={onOk}>OK</button>
        </div>
      </div>
    </div>
  );

  /* キャンセルボタンあり
  return (
    <div className={`custom-dialog ${isVisible ? 'show' : 'hide'}`}>
      <div className="dialog-content">
        <p>{message}</p>
        <div className="button" onKeyDown={handleKeyDown}>
          <button ref={okButtonRef} className="ok focus-visible" onClick={onOk}>OK</button>
          <button ref={cancelButtonRef} className="cancel" onClick={onCancel}>キャンセル</button>
        </div>
      </div>
    </div>
  );
  */
};

export default CustomDialog;
