// 祝日
export const holiday = [
    '20240101',
    '20240108',
    '20240211',
    '20240212',
    '20240223',
    '20240320',
    '20240429',
    '20240503',
    '20240504',
    '20240505',
    '20240506',
    '20240715',
    '20240811',
    '20240812',
    '20240916',
    '20240922',
    '20240923',
    '20241014',
    '20241103',
    '20241104',
    '20241123',
    '20250101',
    '20250113',
    '20250211',
    '20250223',
    '20250224',
    '20250320',
    '20250429',
    '20250503',
    '20250504',
    '20250505',
    '20250506',
    '20250721',
    '20250811',
    '20250915',
    '20250923',
    '20251013',
    '20251103',
    '20251123',
    '20251124',
    '20260101',
    '20260112',
    '20260211',
    '20260223',
    '20260320',
    '20260429',
    '20260503',
    '20260504',
    '20260505',
    '20260506',
    '20260720',
    '20260811',
    '20260921',
    '20260922',
    '20260923',
    '20261012',
    '20261103',
    '20261123',
    '20270101',
    '20270111',
    '20270211',
    '20270223',
    '20270321',
    '20270322',
    '20270429',
    '20270503',
    '20270504',
    '20270505',
    '20270719',
    '20270811',
    '20270920',
    '20270923',
    '20271011',
    '20271103',
    '20271123',
    '20280101',
    '20280110',
    '20280211',
    '20280223',
    '20280320',
    '20280429',
    '20280503',
    '20280504',
    '20280505',
    '20280717',
    '20280811',
    '20280918',
    '20280922',
    '20281009',
    '20281103',
    '20281123',
    '20290101',
    '20290108',
    '20290211',
    '20290212',
    '20290223',
    '20290320',
    '20290429',
    '20290430',
    '20290503',
    '20290504',
    '20290505',
    '20290716',
    '20290811',
    '20290917',
    '20290923',
    '20290924',
    '20291008',
    '20291103',
    '20291123',
    '20300101',
    '20300114',
    '20300211',
    '20300223',
    '20300320',
    '20300429',
    '20300503',
    '20300504',
    '20300505',
    '20300506',
    '20300715',
    '20300811',
    '20300812',
    '20300916',
    '20300923',
    '20301014',
    '20301103',
    '20301104',
    '20301123',
    '20310101',
    '20310113',
    '20310211',
    '20310223',
    '20310224',
    '20310321',
    '20310429',
    '20310503',
    '20310504',
    '20310505',
    '20310506',
    '20310721',
    '20310811',
    '20310915',
    '20310923',
    '20311013',
    '20311103',
    '20311123',
    '20311124',
    '20320101',
    '20320112',
    '20320211',
    '20320223',
    '20320320',
    '20320429',
    '20320503',
    '20320504',
    '20320505',
    '20320719',
    '20320811',
    '20320920',
    '20320921',
    '20320922',
    '20321011',
    '20321103',
    '20321123',
    '20330101',
    '20330110',
    '20330211',
    '20330223',
    '20330320',
    '20330321',
    '20330429',
    '20330503',
    '20330504',
    '20330505',
    '20330718',
    '20330811',
    '20330919',
    '20330923',
    '20331010',
    '20331103',
    '20331123',
    '20340101',
    '20340102',
    '20340109',
    '20340211',
    '20340223',
    '20340320',
    '20340429',
    '20340503',
    '20340504',
    '20340505',
    '20340717',
    '20340811',
    '20340918',
    '20340923',
    '20341009',
    '20341103',
    '20341123',
    '20350101',
    '20350108',
    '20350211',
    '20350212',
    '20350223',
    '20350321',
    '20350429',
    '20350430',
    '20350503',
    '20350504',
    '20350505',
    '20350716',
    '20350811',
    '20350917',
    '20350923',
    '20350924',
    '20351008',
    '20351103',
    '20351123',
    '20360101',
    '20360114',
    '20360211',
    '20360223',
    '20360320',
    '20360429',
    '20360503',
    '20360504',
    '20360505',
    '20360506',
    '20360721',
    '20360811',
    '20360915',
    '20360922',
    '20361013',
    '20361103',
    '20361123',
    '20361124',
    '20370101',
    '20370112',
    '20370211',
    '20370223',
    '20370320',
    '20370429',
    '20370503',
    '20370504',
    '20370505',
    '20370506',
    '20370720',
    '20370811',
    '20370921',
    '20370922',
    '20370923',
    '20371012',
    '20371103',
    '20371123',
    '20380101',
    '20380111',
    '20380211',
    '20380223',
    '20380320',
    '20380429',
    '20380503',
    '20380504',
    '20380505',
    '20380719',
    '20380811',
    '20380920',
    '20380923',
    '20381011',
    '20381103',
    '20381123',
    '20390101',
    '20390110',
    '20390211',
    '20390223',
    '20390321',
    '20390429',
    '20390503',
    '20390504',
    '20390505',
    '20390718',
    '20390811',
    '20390919',
    '20390923',
    '20391010',
    '20391103',
    '20391123',
    '20400101',
    '20400102',
    '20400109',
    '20400211',
    '20400223',
    '20400320',
    '20400429',
    '20400430',
    '20400503',
    '20400504',
    '20400505',
    '20400716',
    '20400811',
    '20400917',
    '20400922',
    '20401008',
    '20401103',
    '20401123',
    '20410101',
    '20410114',
    '20410211',
    '20410223',
    '20410320',
    '20410429',
    '20410503',
    '20410504',
    '20410505',
    '20410506',
    '20410715',
    '20410811',
    '20410812',
    '20410916',
    '20410923',
    '20411014',
    '20411103',
    '20411104',
    '20411123',
    '20420101',
    '20420113',
    '20420211',
    '20420223',
    '20420224',
    '20420320',
    '20420429',
    '20420503',
    '20420504',
    '20420505',
    '20420506',
    '20420721',
    '20420811',
    '20420915',
    '20420923',
    '20421013',
    '20421103',
    '20421123',
    '20421124',
    '20430101',
    '20430112',
    '20430211',
    '20430223',
    '20430321',
    '20430429',
    '20430503',
    '20430504',
    '20430505',
    '20430506',
    '20430720',
    '20430811',
    '20430921',
    '20430922',
    '20430923',
    '20431012',
    '20431103',
    '20431123',
    '20440101',
    '20440111',
    '20440211',
    '20440223',
    '20440320',
    '20440321',
    '20440429',
    '20440503',
    '20440504',
    '20440505',
    '20440718',
    '20440811',
    '20440919',
    '20440922',
    '20441010',
    '20441103',
    '20441123',
    '20450101',
    '20450102',
    '20450109',
    '20450211',
    '20450223',
    '20450320',
    '20450429',
    '20450503',
    '20450504',
    '20450505',
    '20450717',
    '20450811',
    '20450918',
    '20450922',
    '20451009',
    '20451103',
    '20451123',
    '20460101',
    '20460108',
    '20460211',
    '20460212',
    '20460223',
    '20460320',
    '20460429',
    '20460430',
    '20460503',
    '20460504',
    '20460505',
    '20460716',
    '20460811',
    '20460917',
    '20460923',
    '20460924',
    '20461008',
    '20461103',
    '20461123',
    '20470101',
    '20470114',
    '20470211',
    '20470223',
    '20470321',
    '20470429',
    '20470503',
    '20470504',
    '20470505',
    '20470506',
    '20470715',
    '20470811',
    '20470812',
    '20470916',
    '20470923',
    '20471014',
    '20471103',
    '20471104',
    '20471123',
    '20480101',
    '20480113',
    '20480211',
    '20480223',
    '20480224',
    '20480320',
    '20480429',
    '20480503',
    '20480504',
    '20480505',
    '20480506',
    '20480720',
    '20480811',
    '20480921',
    '20480922',
    '20481012',
    '20481103',
    '20481123',
    '20490101',
    '20490111',
    '20490211',
    '20490223',
    '20490320',
    '20490429',
    '20490503',
    '20490504',
    '20490505',
    '20490719',
    '20490811',
    '20490920',
    '20490921',
    '20490922',
    '20491011',
    '20491103',
    '20491123',
    '20500101',
    '20500110',
    '20500211',
    '20500223',
    '20500320',
    '20500321',
    '20500429',
    '20500503',
    '20500504',
    '20500505',
    '20500718',
    '20500811',
    '20500919',
    '20500923',
    '20501010',
    '20501103',
    '20501123',    
]
