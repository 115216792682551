import React, { useEffect, useRef } from 'react';
import './CustomAlert.css'; // スタイル用のCSSをインポート

function CustomAlert(props) {
  const okButtonRef = useRef(null);
  const returnFocus = useRef(null);

  useEffect(() => {
    // アラートが表示されたときにOKボタンにフォーカスをセットする
    if (props.isVisible) {
      returnFocus.current = document.activeElement;
      okButtonRef.current.focus();
    } else {
      // アラートが非表示になったときに元の場所にフォーカスを戻す
      returnFocus.current && returnFocus.current.focus();
    }
  }, [props.isVisible]);

  const handleKeyDown = (e) => {
    if (e.key === 'Tab') {
      e.preventDefault();
      okButtonRef.current.focus();
    }
  };

  return (
    <div className={`vba-message-box-overlay ${props.isVisible ? 'show' : 'hide'}`}>
      <div className={`vba-message-box ${props.isVisible ? 'show' : 'hide'}`}>
        <div className="vba-message-box-content">
          <div className="vba-message-box-text">
            <p>{props.message}</p>
          </div>
        </div>
        <div className="vba-message-box-buttons" onKeyDown={handleKeyDown}>
          <button ref={okButtonRef} onClick={() => { props.onClose(); returnFocus.current && returnFocus.current.focus(); }}>OK</button>
        </div>
      </div>
    </div>
  );
}

export default CustomAlert;