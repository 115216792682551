import React from 'react';
import TextField from '@mui/material/TextField';

const CustomTextField = ({ label, value, onChange, ...props }) => {
  console.log(`Rendering CustomTextField: ${label}`);

  return (
    <TextField
      label={label}
      value={value}
      onChange={onChange}
      variant="outlined"
      fullWidth
      {...props}
    />
  );
};

const areEqual = (prevProps, nextProps) => {
  return (
    prevProps.value === nextProps.value &&
    prevProps.label === nextProps.label
  );
};

export default React.memo(CustomTextField, areEqual);
