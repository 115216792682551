import React, { useContext,useEffect,useState, forwardRef, useImperativeHandle } from 'react';
import TimeContext from './TimeContext';  // useServerTime カスタムフックをインポート
import { UserContext } from './App';
import CustomDialog from './CustomDialog';

//サーバ時刻読み込み

// function Clock2() {
const Clock2 = forwardRef((props, ref) => {

    const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
    const {timeRef,time} = useContext(TimeContext);  // Context から time を取得
    const {state, dispatch} = useContext(UserContext);

    const [timer, setTimer] = useState(false);          // タイマー実行有無
    const caveatTime = [300,60,0];                      // 警告表示時間（秒　5分=300秒、1分=60秒）
    const [simkrTime,setSimkrtime] = useState('');      // サーバ時間
    const [msgflg5,setMsgflg5] = useState(0);           // 5分前メッセージフラグ
    const [msgflg1,setMsgflg1] = useState(0);           // 1分前メッセージフラグ

    // ダイアログ表示↓↓↓
    const [isDialogOpen, setDialogOpen] = useState(false);
    const [dialogMessage, setDialogMessage] = useState('');
    
    var timerId = []; //new Array();

    useImperativeHandle(ref, () => ({
        stopTimer,
        timerCheck
    }));    

    useEffect(()=>{
        // システム日付移送
        let d = new Date(state.SDAY);
        let year = d.getFullYear();
        let month = d.getMonth() + 1;
        let day = d.getDate();
        setsDate(year + '/' + month + '/' + day);
    },[]);

    // ダイアログを表示
    const showDialog = (message) => {
        window.glAlertDialogFlg = true;
        setDialogMessage(message);   // メッセージ
        setDialogOpen(true);
    };

    // OKボタンがクリックされたときの処理
    const handleOk = () => {
        window.glAlertDialogFlg = true;
        setDialogOpen(false);
    };

    // 締切時間チェック実施確認
    const timerCheck = (simeTime) => {
        let ndate = new Date();
        let today = ndate.getFullYear() + ('0'+(ndate.getMonth()+1)).slice(-2) + ('0'+ndate.getDate()).slice(-2)
        //simkrTime = simeTime
        setSimkrtime(simeTime);

        console.log('タイマーID:',timerId.length)
        // タイマーが起動している場合、停止させる。
        if (timerId.length >= 1){
            console.log('タイマー停止:',timerId.length)
            // setTimer(false);
            // timerId = [];
            stopTimer();        // タイマー停止
        }

        console.log('タイマー開始:')
        console.log('today:',today)
        console.log('state.HTTYMD:',state.HTTYMD)
        //console.log('締切時間:',httItem.SIMTIM)
        console.log('締切時刻:',simeTime)
        // 締切時間が取得できた場合
        if (simeTime){
            // 発注日が当日の場合、タイマー起動
            if (today == state.HTTYMD){
                console.log('タイマー開始:',timerId.length)
                setMsgflg5(0);
                setMsgflg1(0);
                setTimer(true);
            }  
        }
    };

    // 締切時間タイマー停止時
    const stopTimer = () => {
        console.log('タイマー停止')
        setTimer(false);    // タイマー停止
        timerId = [];       // タイマーID初期化
    }

    // ②締切時間　５分前・１分前の注意喚起画面表示
    const timeUp = () => {
        
        let timemessage = '';                           // 出力メッセージ
        let today = new Date(sdate);                    // 年月日取得用
        let yyyy = today.getFullYear();                 // 年(2024)
        let mm = ('0'+(today.getMonth()+1)).slice(-2);  // 月(04)
        let dd = ('0'+today.getDate()).slice(-2);       // 日(05)
        // let hh = httItem.SIMTIM.slice(0,2);             // 時(15)
        // let nn = httItem.SIMTIM.slice(-2);              // 分(30)
        let hh = simkrTime.slice(0,2);                  // 時(15)
        let nn = simkrTime.slice(-2);                   // 分(30)
        let ss = '00';                                  // 秒(00)

        // サーバ時間（残り時間計算用）
        let date1 = new Date(yyyy + '-' + mm + '-' + dd + ' ' + time.toLocaleTimeString());
        // 締時間（残り時間計算用）
        let date2 = new Date(yyyy + '-' + mm + '-'+ dd + ' ' + hh + ':' + nn + ':' + ss);
        //テスト用設定時間
        //let date2 = new Date(yyyy + '-' + mm + '-'+ dd + ' ' + '10:30:00');
        let diff = date2.getTime() - date1.getTime();   // 残り時間（ミリ秒）
        let checkTime = diff /1000                      // 残り時間（秒）
        // console.log('締切時刻:',hh + ':' + nn + ':' + ss)
        // console.log('サーバ時間:',time.toLocaleTimeString())
        // console.log('残り時間:',checkTime)
        
        // ５分前、１分前、締切り
        //if(checkTime == 300){     //残り時間300秒の場合
        if(checkTime > caveatTime[0]){     //残り時間300秒以上フラグ解除
            setMsgflg5(0);
            setMsgflg1(0);
        }
        else if(checkTime <= caveatTime[2]){  //残り時間0秒以下の場合
            // timemessage = '締め切り時間を過ぎました'
            timemessage = '締時間が過ぎているため、入力を確定できません。'
            console.log(timemessage);
            handleOk();
            showDialog(timemessage);
            // CustomDialog.onOk.current.focus();
            // setTimer(false);
            // timerId = [];
            stopTimer();        // タイマー停止
            window.dispatchEvent(new CustomEvent('timerStopped', { detail: { message: 'タイマーが停止されました' } }));
        }
        else if(checkTime <= caveatTime[1] && msgflg1 === 0){ //残り時間60秒の場合
            timemessage = '締め切り時間１分前です'
            console.log(timemessage);
            handleOk();
            showDialog(timemessage);
            setMsgflg1(1);
            setMsgflg5(1);
        }
        else if(checkTime <= caveatTime[0] && msgflg5 === 0){     //残り時間300秒の場合
            timemessage = '締め切り時間５分前です'
            console.log(timemessage);
            showDialog(timemessage);
            console.log(caveatTime[0]);
            setMsgflg5(1);
        }
        //else if(checkTime == 60){ //残り時間300秒の場合
        //else if(checkTime <= 0){  //残り時間0秒以下の場合
    };

    useEffect(() => {
        // console.log('時間チェック:'+time.toLocaleTimeString());
        if (timer) {
          timerId.push(setInterval(timeUp(), 1000));
        //   timerId = setInterval(timeUp, 1000);  // 修正：関数を直接指定
        }else{
          clearInterval(timerId.shift());
        }
    }, [timer,time]);
    
    return (
        <box>
            <CustomDialog
                isVisible={isDialogOpen}        // 表示/非表示
                message={dialogMessage}         // 出力メッセージ
                onOk={handleOk}                 // OKボタン
                // onCancel={handleCancel}        // キャンセルボタン
            />      
            {time.toLocaleTimeString()}
        </box>
    );
});

export default Clock2;