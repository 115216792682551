import React from 'react';
import Button from '@mui/material/Button';

const CustomButton = React.memo(({ onClick, disabled, children, width }) => {
  console.log('CustomButton rendered');
  return (
    <Button
      variant="contained"
      color="success"
      sx={{ width: {width}, mr: '5px', mt: '10px' }}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </Button>
  );
});

export default CustomButton;
