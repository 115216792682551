import React, {createContext,useReducer} from 'react'
import { Routes, Route } from 'react-router-dom';
import TCSH0000 from './TCSH0000';
import TCSH0010 from './TCSH0010';
import TCSH0020 from './TCSH0020';
import TCSH0021 from './TCSH0021';
import TCSH0030 from './TCSH0030';
import TCSH0040 from './TCSH0040';
//S SK-0050-024 2024/7/26           TCI)M.K【要望：ログイン制御】
import TCSH0050 from './TCSH0050';
//E SK-0050-024
import TCSH0100 from './TCSH0100';
import TCSH0101 from './TCSH0101';
import TCSH0130 from './TCSH0130';
import TCSH0151 from './TCSH0151';
import TCSH0170 from './TCSH0170';
import TCSH0180 from './TCSH0180';
import TCSH0200 from './TCSH0200';
import TCSH0201 from './TCSH0201';
import TCSH0280 from './TCSH0280';
//S SK-0078-001 2024/10/4           TCI)M.K【課題No.104：レスポンス対応２】
// import TCSA0002 from './TCSA0002';
// import TCSA0003 from './TCSA0003';
// import TCSA0007 from './TCSA0007';
// import TCSA0004 from './TCSA0004';
// import TCSA0005 from './TCSA0005';
// import TCSA0006 from './TCSA0006';
// import TCSA0100 from './TCSA0100';
// import TCSA0130 from './TCSA0130';
// import TCSA0140 from './TCSA0140';
// import TCSA0150 from './TCSA0150';
// import TCSA0160 from './TCSA0160';
// import TCSA0180 from './TCSA0180';
// import TCSA0190 from './TCSA0190';
// import TCSA0200 from './TCSA0200';
// import TCSA0210 from './TCSA0210';
// import TCSA0220 from './TCSA0220';
// import TCSA1110 from './TCSA1110';
// import TCGT0100 from './TCGT0100';
// import TCGT0110 from './TCGT0110';
// import TCGT0170 from './TCGT0170';
// import TCGT0180 from './TCGT0180';
// import TCHP0150 from './TCHP0150';
// import TCHP0151 from './TCHP0151';
// import TCHP0160 from './TCHP0160';
// import TCHP0161 from './TCHP0161';
// import TCPD0130 from './TCPD0130';
// import TCPD0131 from './TCPD0131';
// import TCPD0140 from './TCPD0140';
// import TCPD0141 from './TCPD0141';
// import TCSA9999 from './TCSA9999';
//E SK-0078-001
import { TimeProvider } from './TimeContext';

export const UserContext = createContext()

const loginState = {                    //useContext初期値
  GAMEN: 0,
  KSACDE: '',
  MISE: '',
  KSACDES: '',
  MISES: '',
  K_KSACDES: '',
  K_MISES: '',
  MISEMEI: '',
  JGNCDE: '',
  JGNMEIKNJ: '',
  KENGEN: '',
  SMISEFLG: '',
  SMISEJH:'',       //new
  MVEMGAMEN: '',
  K_FLG: '',
  NHNYMD: '',
  BIN: '',
  ETC: '',          //new
  SDAY: '',         //new
  BUTTON: '',        //new
  BMNCDE: '',          //【ス発注】部門コード
  BRUMEI: '',          //【ス発注】部門名
  GRPCDE: '',          //【ス発注】グループコード
  IRYOFLG: '',         //【ス発注】衣料品フラグ
  HTTYMD: '',          //【ス発注】発注日付
  HTTKBN: '',          //【ス発注】発注区分
  IN_CD_KBN: '',       //【ス発注】ＪＡＮ/ＥＯＳ区分
  JANCDE: '',          //【ス発注】JANコード ↓↓↓ 商品情報表示用構造体
  JANCDE2: '',         //【ス発注】JANコード2
  EOSCDE: '',          //【ス発注】EOSコード
  TRIHBN: ''          //【ス発注】取引先品番
};

const reducer = (state, action) => {    //useContext更新処理
  if (action.type === 'Update') {
    return { 
      GAMEN: action.GAMEN,            //画面情報：0:Windows、1:Android
      KSACDE: action.KSACDE,          //会社コード
      MISE: action.MISE,              //店コード
      KSACDES: action.KSACDES,        //会社コードS
      MISES: action.MISES,            //店コードS
      K_KSACDES: action.K_KSACDES,    //各会社コードS
      K_MISES: action.K_MISES,        //各店コードS
      MISEMEI: action.MISEMEI,        //店名
      JGNCDE: action.JGNCDE,          //従業員コード
      JGNMEIKNJ: action.JGNMEIKNJ,    //担当者氏名
      KENGEN: action.KENGEN,          //権限
      SMISEFLG: action.SMISEFLG,      //複数店舗FLG
      SMISEJH: action.SMISEJH,        //店舗情報最大１０(例 001,A店,002,B店・・・)  new
      MVEMGAMEN: action.MVEMGAMEN,    //移動元画面
      K_FLG: action.K_FLG,            //各店FLG
      NHNYMD: action.NHNYMD,          //実納品日
      BIN: action.BIN,                //便
      ETC: action.ETC,                //汎用項目（カンマ形式パラメータを設定して下さい[次の画面に連携するのみで使用してください]）
      SDAY: action.SDAY,              //汎用項目（カンマ形式パラメータを設定して下さい[次の画面に連携するのみで使用してください]）
      BUTTON: action.BUTTON,          //ボタン権限(例 01,1,02,1・・・)  new
      BMNCDE: action.BMNCDE,          //【ス発注】部門コード
      BRUMEI: action.BRUMEI,          //【ス発注】部門名
      GRPCDE: action.GRPCDE,          //【ス発注】グループコード
      IRYOFLG: action.IRYOFLG,        //【ス発注】衣料品フラグ
      HTTYMD: action.HTTYMD,          //【ス発注】発注日付
      HTTKBN: action.HTTKBN,          //【ス発注】発注区分
      IN_CD_KBN: action.IN_CD_KBN,    //【ス発注】ＪＡＮ/ＥＯＳ区分
      JANCDE: action.JANCDE,          //【ス発注】JANコード ↓↓↓ 商品情報表示用構造体
      JANCDE2: action.JANCDE2,        //【ス発注】JANコード2
      EOSCDE: action.EOSCDE,          //【ス発注】EOSコード
      TRIHBN: action.TRIHBN,          //【ス発注】取引先品番
    };
  } else {
    return { 
      GAMEN: 0,
      KSACDE: '',
      MISE: '',
      KSACDES: '',
      MISES: '',
      K_KSACDES: '',
      K_MISES: '',
      MISEMEI: '',
      JGNCDE: '',
      JGNMEIKNJ: '',
      KENGEN: '',
      SMISEFLG: '',
      SMISEJH:'',       //new
      MVEMGAMEN: '',
      K_FLG: '',
      NHNYMD: '',
      BIN: '',
      ETC: '',          //new
      SDAY: '',         //new
      BUTTON: '',        //new
      BMNCDE: '',          //【ス発注】部門コード
      BRUMEI: '',          //【ス発注】部門名
      GRPCDE: '',          //【ス発注】グループコード
      IRYOFLG: '',         //【ス発注】衣料品フラグ
      HTTYMD: '',          //【ス発注】発注日付
      HTTKBN: '',          //【ス発注】発注区分
      IN_CD_KBN: '',       //【ス発注】ＪＡＮ/ＥＯＳ区分
      JANCDE: '',          //【ス発注】JANコード ↓↓↓ 商品情報表示用構造体
      JANCDE2: '',         //【ス発注】JANコード2
      EOSCDE: '',          //【ス発注】EOSコード
      TRIHBN: '',          //【ス発注】取引先品番
    };
  }
};

function App() {

  const [state, dispatch] = useReducer(reducer, loginState);  // useContext作成
  window.resizeBy(300,300);
  return (
    <div className="App">
      {/* <h1>Hello React Router v6</h1> */}
      <TimeProvider> {/* TimeProvider を最外層に追加 */}
        <UserContext.Provider value={{ state, dispatch }}>    {/* useContext作成 */}
          <Routes>
            <Route path="/" element={<TCSH0000 />} />
            <Route path="/TCSH0010" element={<TCSH0010 />} />
            <Route path="/TCSH0020" element={<TCSH0020 />} />
            <Route path="/TCSH0021" element={<TCSH0021 />} />
            <Route path="/TCSH0030" element={<TCSH0030 />} />
            <Route path="/TCSH0040" element={<TCSH0040 />} />
            {/* S SK-0050-025 2024/7/26           TCI)M.K【要望：ログイン制御】 */}
            <Route path="/TCSH0050" element={<TCSH0050 />} />
            {/* E SK-0050-025 */}
            <Route path="/TCSH0100" element={<TCSH0100 />} />
            <Route path="/TCSH0101" element={<TCSH0101 />} />
            <Route path="/TCSH0130" element={<TCSH0130 />} />
            <Route path="/TCSH0151" element={<TCSH0151 />} />
            <Route path="/TCSH0170" element={<TCSH0170 />} />
            <Route path="/TCSH0180" element={<TCSH0180 />} />
            <Route path="/TCSH0200" element={<TCSH0200 />} />
            <Route path="/TCSH0201" element={<TCSH0201 />} />
            <Route path="/TCSH0280" element={<TCSH0280 />} />
            {/* S SK-0078-002 2024/10/4           TCI)M.K【課題No.104：レスポンス対応２】 */}
            {/*
            <Route path="/TCSA0002" element={<TCSA0002 />} />
            <Route path="/TCSA0003" element={<TCSA0003 />} />
            <Route path="/TCSA0007" element={<TCSA0007 />} />
            <Route path="/TCSA0004" element={<TCSA0004 />} />
            <Route path="/TCSA0005" element={<TCSA0005 />} />
            <Route path="/TCSA0006" element={<TCSA0006 />} />
            <Route path="/TCSA0100" element={<TCSA0100 />} />
            <Route path="/TCSA0130" element={<TCSA0130 />} />
            <Route path="/TCSA0140" element={<TCSA0140 />} />
            <Route path="/TCSA0150" element={<TCSA0150 />} />
            <Route path="/TCSA0160" element={<TCSA0160 />} />
            <Route path="/TCSA0180" element={<TCSA0180 />} />
            <Route path="/TCSA0190" element={<TCSA0190 />} />
            <Route path="/TCSA0200" element={<TCSA0200 />} />
            <Route path="/TCSA0210" element={<TCSA0210 />} />
            <Route path="/TCSA0220" element={<TCSA0220 />} />
            <Route path="/TCSA1110" element={<TCSA1110 />} />
            <Route path="/TCGT0100" element={<TCGT0100 />} />
            <Route path="/TCGT0110" element={<TCGT0110 />} />
            <Route path="/TCGT0170" element={<TCGT0170 />} />
            <Route path="/TCGT0180" element={<TCGT0180 />} />
            <Route path="/TCHP0150" element={<TCHP0150 />} />
            <Route path="/TCHP0151" element={<TCHP0151 />} />
            <Route path="/TCHP0160" element={<TCHP0160 />} />
            <Route path="/TCHP0161" element={<TCHP0161 />} />
            <Route path="/TCPD0130" element={<TCPD0130 />} />
            <Route path="/TCPD0131" element={<TCPD0131 />} />
            <Route path="/TCPD0140" element={<TCPD0140 />} />
            <Route path="/TCPD0141" element={<TCPD0141 />} />
            <Route path="/TCSA9999" element={<TCSA9999 />} />
            */}
            {/* E SK-0078-002 */}
            {/* S SK-0075-001 2024/9/10           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】 */}
            <Route path="*" element={<TCSH0000 />} />
            {/* E SK-0075-001 */}
            </Routes>
        </UserContext.Provider>
      </TimeProvider>
    </div>
  );
}

export default App;