import React, { useState,useContext,useEffect,useRef } from 'react'
import { Box, Button,Stack, Grid, Paper, Typography, InputLabel, MenuItem, FormControl, TextField, Select } from "@mui/material";
import Table from '@mui/material/Table';              //テーブル用
import TableBody from '@mui/material/TableBody';      //テーブル用
import TableCell from '@mui/material/TableCell';      //テーブル用
import TableContainer from '@mui/material/TableContainer';  //テーブル用
import TableHead from '@mui/material/TableHead';      //テーブル用
import TableRow from '@mui/material/TableRow';        //テーブル用
import { NumericFormat,PatternFormat } from 'react-number-format';    //数値入力
import { useNavigate } from 'react-router-dom';       //画面遷移ボタン処理用
import { UserContext } from './App';
import {
  DataGrid,
  jaJP                              // 日本語用のファイルをインポート
} from '@mui/x-data-grid';

const commonStyles = {
  bgcolor: 'background.paper',
  m: 1,
  border: 0,
  width: '40px',
  height: '40px',
};

//ファンクションキー対応
const useEventListener = (eventName, handler) => {
  useEffect(() => {
    const eventListener = (event) => {
      handler(event);
      
      if (event.key === "F1") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F5") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
      if (event.key === "F9") { // F1キーが押された場合の処理
        event.preventDefault(); // デフォルトの動作をキャンセル
      }
    };    // イベントリスナーを追加
    window.addEventListener(eventName, eventListener);
    // コンポーネントのアンマウント時にイベントリスナーを削除
    return () => {
      window.removeEventListener(eventName, eventListener);
    };
  }, [eventName, handler]);
};
//ファンクションキー対応

function TCSH0180 () {

  const navigate = useNavigate();
  const {state, dispatch} = useContext(UserContext);
  const [httkbn, setHttkbn] = useState('1');
  const [httymd, setHttymd] =  useState('');
  const [alert, setAlert] = useState('');
  const [lK_FLG,setlK_FLG] = useState('');
  const [sdate, setsDate] = useState([]);     // 本日日付（YYYY/MM/DD)
  const [bin, setBin] = useState('1');
  const [rows, setRows] = useState([]);
  //S SK-0080-003 2024/10/8           TCI)M.K【要望：発注明細確認一覧】
  const [printdata, setPrintData] = useState([]);
  const [upd_flg, setUpdflg] = useState('0');   //更新用フラグ
  //E SK-0080-003

  const inHTTYMD = useRef(null);
  const inBIN = useRef(null);
  const inJAN1 = useRef(null);
  const inJAN2 = useRef(null);
  const inSUU = useRef(null);
  const inSHNMEI = useRef(null);
  const inSRYFOT = useRef(null);
  const inBAKFOT = useRef(null);
  const [totalSuryo, setTotalSuryo] = useState(0);     //数量合計
  const [totalBaika, setTotalBaika] = useState(0);     //売価金額合計

  //disabled制御用
  const [disBtnOrderbyS, setdisBtnOrderbyS] = useState(false);      // 仕入先順
  const [disBtnOrderbyE, setdisBtnOrderbyE] = useState(false);      // 入力順

  // ロストフォーカス用
  const [chkJAN1,setChkJAN1] = useState('0');

  //項目のタイトル変更用
  const [titleHTTKBN,settitleHTTKBN] = useState('');
  
  const [selectionModel, setSelectionModel] = useState([]);
  const [pagenationModel, setPagenationModel] = useState({page: 0, pageSize: 10});

  //S SK-0075-014 2024/9/20           TCI)M.K【課題No.98：URL直接入力は初画面に戻す】
  const handlebeforeunload = (event) => {
    // デフォルトの 'beforeunload' イベントをキャンセル
    event.preventDefault();  // 正しい記述
    event.returnValue = '';  // 必須: これでブラウザに確認ダイアログが表示されます
    console.log('beforeunload イベントが発火しました');
  };
  
  useEffect(() => {
    // 'beforeunload' のイベントリスナーを登録
    window.addEventListener('beforeunload', handlebeforeunload);
  
    // クリーンアップ関数（イベントリスナーを削除）
    return () => {
      window.removeEventListener('beforeunload', handlebeforeunload);
    };
  }, []);  // handlebeforeunload が依存していないため、依存配列は空にして問題ありません 
  //E SK-0075-014

  //ファンクションキー対応
  const handleKeyDown = (event) => {
    // 多重防止
    // if (!keyDisabled) {
    //   setKeyDisabled(true);
    // } else {
    //   return;
    // }

    if (event.keyCode === 112) { // F1キーが押された場合の処理
    }
    if (event.keyCode === 116) { // F5キーが押された場合の処理
      // if (state.MVEMGAMEN==='')
      // {
      //   navigate('/');
      // }else{
      //   navigate('/TCSA0003');
      // }      
    }
    if (event.keyCode === 120) { // F2キーが押された場合の処理
    }
  };
  useEventListener("keydown", handleKeyDown);
  //ファンクションキー対応

  const handleChange = (event, SelectChangeEvent) => {
    setHttkbn(event.target.value);
    console.log('選択発注区分:%o',event.target.value)
    switch(event.target.value)
    {
      case '1':
        break;
      case '2':
        break;
      default:
        break;
    };
    
    // inMISE.current.focus();
    // inMISE.current.select();
  };
  
  // 金額表示フォーマット
  const formatCurrency = (amount) => {
    if(amount === null){ return amount; }

    return amount.toLocaleString('ja-JP', {
      style: 'currency',
      currency: 'JPY',
      minimumFractionDigits: 0,
    });
  }

  useEffect (()=>{
    if (state.KENGEN === '')
    {
      console.log('直リン不可'+state.KENGEN);
      navigate('/');
    }else
    {
      console.log('メニュー:%o',state)
    }

    // システム日付移送
    let d = new Date(state.SDAY);
    let year = d.getFullYear();
    let month = d.getMonth() + 1;
    let day = d.getDate();
    setsDate(year + '/' + month + '/' + day);

    // 発注日移送
    setHttymd(state.HTTYMD);
    
    // 発注区分タイトル変更
    if(state.HTTKBN==='1')     //衣料
    {
      settitleHTTKBN('定番');
    }else{
      settitleHTTKBN('特売');
    }
    
    // 検索
    Htt_Sel_ALL('ENT_TIM');
    //S SK-0080-005 2024/10/8           TCI)M.K【要望：発注明細確認一覧】
    console.log('権限:'+state.KENGEN);
    if (state.KENGEN === '99')
    {
      setUpdflg('1');
    }
    //E SK-0080-005
  },[]);

  useEffect(() => {
  }, []);

  // // 検索ボタン押下時の処理（仮）
  // const onClick_FooterButton_Search = async() => {
  //   let errflg = 0;
  //   Htt_Sel_ALL('ENT_TIM');
  //   if (errflg === 0 )
  //   {
  //   }
  // }

  //S SK-0080-002 2024/10/8           TCI)M.K【要望：発注明細確認一覧】
  // テイル部処理　印刷ボタン　押下
  const onClick_FooterButton_Print = async () => {
    // 印刷処理を行うか確認メッセージを表示する
    if (!(window.confirm('印刷処理を行います。よろしいですか？'))) {
      return;
    }

    // 画面に出力されている伝票の明細を全て印刷する。
    console.log(printdata);
    const responseB01 = await fetch('/TCSH0170/SOPRT0010',{method: 'POST',headers: {'Content-Type': 'application/json'},
      body: JSON.stringify({
        MISEMEI: state.MISEMEI,     // ★
        BMNCDE: state.BMNCDE,       // ★
        BRUMEI: state.BRUMEI,       // ★
        JGNMEIKNJ: state.JGNMEIKNJ, // ★
        IRYOFLG: state.IRYOFLG,     // ★ (2024/10/10追加)
        DATAS: printdata,
        UPD_FLG:upd_flg,
      })
    });
    const dataB01 = await responseB01.json();
    console.log(dataB01);
  }
  //E SK-0080-002

  // 戻るボタン押下時の処理
  const onClick_FooterButton_Back = async() => {
    navigate('/TCSH0170');
  }

  // 仕入先コード順ボタン押下時の処理
  const onClick_FooterButton_OrderbyS = async() => {
    Htt_Sel_ALL('SIRCDE');
  }

  // 入力順順ボタン押下時の処理
  const onClick_FooterButton_OrderbyE = async() => {
    Htt_Sel_ALL('ENT_TIM');
  }

  // 送信結果検索・表示
  const Htt_Sel_ALL = async(ORDERBY) => {
    console.log('検索処理開始 :')
    let errflg = 0;

    const responseSl2 = await fetch('/TCSH0170/SH_HTT_DAT_Sel_ALL',{method: 'POST',headers: {'Content-Type': 'application/json'},
    body: JSON.stringify({
      ksacde : state.KSACDE       // 会社コード
      ,mise :  state.MISES        // 店コード
      ,httymd : state.HTTYMD      // 発注日
      ,jgncde : state.JGNCDE      // 従業員コード
      ,orderby : ORDERBY          // 並び替え条件（SIRCDE:仕入先コード順、ENT_TIM:入力順）
    })});
    const dataSl2 = await responseSl2.json();
    //S SK-0080-004 2024/10/8           TCI)M.K【要望：発注明細確認一覧】
    setPrintData(dataSl2);  // 印刷用
    //E SK-0080-004
    console.log('検索結果:%o',dataSl2.RCNT,dataSl2);
    if (dataSl2.RCNT === 0){
      // 異常時→何もしない
    }else{
      // 正常時
      let id = 1;
      const newRows = [];
      dataSl2.ret.forEach(element => {
        let snd_knr_kbn_name = '';
        if(element.SND_KNR_KBN==='1'){
          snd_knr_kbn_name='正常';
        }else if(element.SND_KNR_KBN==='9'){
          snd_knr_kbn_name='異常';
        }else if(element.SND_KNR_KBN==='2'){
          snd_knr_kbn_name='送信済';
        }
        newRows.push({
          id: id,
          field1: {
            data11: element.HTTYMD,       // 発注日付
            //S SK-0026-001 2024/6/27           TCI)M.K【発注明細一覧への発注区分表示変更】
            // data12: element.HTT_KBN + "　",      // 発注区分
            data12: element.HTT_KBN === '2' ? "特売　" : "　　　",      // 発注区分
            //E SK-0026-001
            data13: element.BIN + "便",   // 便
          },
          field2: {
            //S SK-0091-004 2024/10/22           TCI)M.K【要望：SK-0080の追加修正（規格を表示）】
            // data21: element.JANCDE,       // JANコード
            data21: element.HTT_CDE === '1' ?
              element.JANCDE:       // JANコード
              element.EOSCDE,       // EOSコード
            //E SK-0091-004
            data22: element.SIRMEIK,      // 仕入先名
          }, 
          field3: {
            data31:element.SHNMEIKN,      // 商品名
            //S SK-0091-002 2024/10/22           TCI)M.K【要望：SK-0080の追加修正（規格を表示）】
        		// //S SK-0083-002 2024/10/8           TCI)M.K【課題No.111：サイズ・カラー名表示】
            // // // data32:element.IRISUU,        // 入数
            // // data32:'入数' + element.IRISUU,  // 入数 2024/06/06修正
            // data32: state.IRYOFLG !== '1' ?
            //   '入数' + element.IRISUU :        // 入数
            //   '入数' + element.IRISUU + ' ' + 
            //   element.TRIHBN + ' ' + element.TAGSIZCDE + ' ' + element.TAGSIZMEI
            //   + ' ' + element.TAGCOLCDE + ' ' + element.TAGCOLMEI,
        		// //E SK-0083-002
            data32: state.IRYOFLG !== '1' ?
              element.KIKAKUKN.trim() + ' 入数' + element.IRISUU :        // 規格＋入数
              element.KIKAKUKN.trim() + ' 入数' + element.IRISUU + ' ' + 
              element.TRIHBN + ' ' + element.TAGSIZCDE + ' ' + element.TAGSIZMEI
              + ' ' + element.TAGCOLCDE + ' ' + element.TAGCOLMEI,
            //E SK-0091-002
          },
          field4: {
            data41:Number(element.BTN),           // 売単価
            data42:element.BTN*element.HTTSUU,    // 金額
          },
          field5: {
            data51:element.HTTSUU,        // 数量
            data52:element.HTTTNI,        // 発注単位名
          },
          field6: {
            data61:snd_knr_kbn_name,      // 送信結果
          },
        });
        id++;
      });
      setRows(newRows);

      // 合計数量
      const totalSuryo = newRows.reduce((sum, element) => sum + parseFloat(element.field5.data51), 0);
      setTotalSuryo(totalSuryo);

      // 売価金額合計
      const totalBaika = newRows.reduce((sum, element) => sum + parseInt(element.field4.data42), 0);
      setTotalBaika(totalBaika);

    // buttonの活性・非活性切替
      if (ORDERBY==='ENT_TIM'){
        setdisBtnOrderbyE(true);
        setdisBtnOrderbyS(false);
      }else if (ORDERBY==='SIRCDE'){
        setdisBtnOrderbyE(false);
        setdisBtnOrderbyS(true);
      }
    }
  }

  const onKeyDown =  async (key,inputRef,inputRtRef,inputNxRef) => {  //  入力キー、現在の項目情報、前項目情報、次項目情報
    //console.log(key);
    switch (key) {
      case "Enter":
        switch (inputRef.current.id) {
          default:
            break;
        }
        // inputNxRef.current.focus();   //次の項目に遷移
        break;
      case "Home":
        break;
      case "ArrowUp":
        break;
      case "ArrowDown":
        break;
      default:
        break;
    }
  }; 

  // スタイルオブジェクトのWidthを自動設定する
  const cssInputOutlineSetwidth = (obj, len) => {
    let resObj = obj;
    let calcLen = len;
    const minLen = 4;   // 最小桁数
    const oneLen = 20;  // 1桁につき20pxとして定義
    // 桁数が4以下の項目だと表示上、長さが足りなくなるので4桁で強制的に幅を調整する
    if (len < minLen) {
      calcLen = minLen;
    }
    resObj.width = (oneLen * calcLen).toString() + 'px';  // 桁数に応じたWidthに設定
    return resObj;
  }

  // 確認のWidthを設定する
  const cssInputOutlineRetSetwidth = (obj) => {
    let resObj = obj;
    resObj.width = '50px';
    return resObj;
  }

  // グリッド列情報
  const columns /*: GridColDef[]*/ = [
    // {
    //   field: 'detailAction',
    //   headerName: '　', // セルの右側に罫線を表示するために全角スペースを表示する
    //   align: 'left',
    //   width: 60,
    //   type: 'actions',
    //   // getActions: getDetailAction,
    //   description: '詳細画面を表示',  // ツールチップで表示される説明
    //   hideable: false,          // 非表示にできないようにする
    //   sortable: false,          // ソートできないようにする
    //   hideSortIcons: false,     // ソートアイコンを表示しない
    //   resizable: false,         // リサイズできないようにする
    //   disableExport: false,     // エクスポートに含めないようにする
    //   disableReorder: false,    // 並べ替えできないようにする
    //   filterable: false,        // フィルタリングしないようにする
    //   disableColumnMenu: true  // カラムメニューを表示しない
    // } ,

    //IDは必ず必要みたい
    {
      field: 'id',
      headerName: 'No.',
      align:'center',
      width: 40 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
    },
    {
      field: 'field1',
      headerName: '発注日/区分/便',
      headerAlign: 'center',
      align:'center',
      width: 120 ,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{(params.value.data11)}</Typography>
          <Typography>{(params.value.data12)+(params.value.data13)}</Typography>
        </div>
      )
    },
    {
      field: 'field2',
      headerName: 'JANコード / 取引先',
      headerAlign: 'center',
      width: 190,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{(params.value.data21)}</Typography>
          <Typography>{(params.value.data22)}</Typography>
        </div>
      )
    },
    {
      field: 'field3',
      headerName: '商品名',
      headerAlign: 'center',
      width: 300,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value.data31}</Typography>
          <Typography>{params.value.data32}</Typography>
        </div>
      )
    },
    {
      field: 'field4',
      headerName: '売価/金額',
      headerAlign: 'center',
      width: 100,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{formatCurrency(params.value.data41)}</Typography>
          <Typography>{formatCurrency(params.value.data42)}</Typography>
        </div>
      )
    },
    {
      field: 'field5',
      headerName: '数量/発注単位',
      headerAlign: 'center',
      width: 110,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value.data51}</Typography>
          <Typography>{params.value.data52}</Typography>
        </div>
      )
    },
    {
      field: 'field6',
      headerName: '結果',
      type: 'number',
      headerAlign: 'center',
      width: 60,
      editable: false ,         //編集可能:false
      sortable: false,          // ソートできないようにする
      disableColumnMenu: true,   // カラムメニューを表示しない
      renderCell: (params) => (
        <div>
          <Typography>{params.value.data61}</Typography>
        </div>
      )
    },

  ];

  //罫線表示（Data Grid）
  const gridStyle = {
    transform: 'scale(0.9)',
    // 他のスタイルプロパティをここに追加することもできます
  };

  const styles = {
    // ルールに沿わないかもしれないがpadding指定を各コントロールに入れたくないのでまとめる
    cssIputOutlinPadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssIputOutlinPaddingRightModle: '2px 9px 0px 2px',// 右寄せコントロールの場合 
    cssRecordFontSizePadding: '2px 2px 0px',              // 中央寄せコントロールの場合 
    cssRecordFontSizePaddingRightModle: '2px 0px 0px 2px',// 右寄せコントロールの場合 

    // OutLineのスタイル指定
    // 活性・非活性も同様に文字及びアウトラインColorを黒(#000000)に、ここは任意の色設定可能
    cssInputOutline: {
      // 活性時デザイン
      '& .MuiInputBase-input': {
        color: '#000000',               // 入力文字の色
      },
      '& label': {
        color: '#000000',               // 通常時のラベル色 
      },
      '& .MuiInput-underline:before': {
        borderBottomColor: '#000000',   // 通常時のボーダー色
      },
      '& .MuiInput-underline:hover:not(.Mui-disabled):before': {
        borderBottomColor: '#000000',   // ホバー時のボーダー色
      },
      '& .MuiOutlinedInput-root': {
        '& fieldset': {
          borderColor: '#000000',       // 通常時のボーダー色(アウトライン)
        },
        '&:hover fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // 非活性時デザイン
      "& .MuiInputBase-input.Mui-disabled": {
        WebkitTextFillColor: "#000000", // 非活性時の文字色
      },
      '& label.Mui-disabled': {
        color: '#000000',               // 非活性時のラベル色 
      },
      '& .MuiOutlinedInput-root.Mui-disabled': {
        '& fieldset': {
          borderColor: '#000000',       // 非活性時のボーダー色(アウトライン)
        },
        '&:hover.Mui-disabled fieldset': {
          borderColor: '#000000',       // ホバー時のボーダー色(アウトライン)
        },
      },
      // フォントサイズ
      '& .MuiInputBase-input': {
         fontSize: '1.6rem'
      },
      width: '0px',         // 設定変更するので0pxで固定定義する
    },

    cssRecordFontSize: {
      // フォントサイズ
      '& .MuiInputBase-input': {
        fontSize: '0.7rem'
      },
      fontSize: '0.7rem',
    },

    cssRecordBorder: {
      border:1,
      padding:0,
    },

    cssRecordBorderAddPaddingRight: {
      border:1,
      padding:0,
      paddingRight:'10px',
    },
    cssInputOutlineJotKbn: {
      '> div': {
        minHeight: 'auto !important',
        paddingTop: '.4em',
        paddingBottom: '.3em',
      }
    },
    cssInputSelect: {
      ' label': {
        color: '#000000'
      },
      ' fieldset': {
        borderColor: '#000000'
      },
    },
  }

  return (

    <Grid>
      <Paper
        elevation={3}
        sx={{
          p: 4,
          height: "600px",
          width: "800px",
          m: "20px auto"
        }}
      >
        <Grid
          container
          direction="column"
          justifyContent="flex-start" //多分、デフォルトflex-startなので省略できる。
          alignItems="center"
        >
          <Box width={'100%'} display={'flex'} justifyContent={'flex-end'}>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'1px',fontSize:11,width:350,mr:'200px' }}>
              {state.MISES}:{state.BMNCDE} {state.MISEMEI}:{state.BRUMEI}<br/>{state.JGNMEIKNJ}
            </Typography>
            <Typography variant={'h3'} sx={{ m: '5px',mt:'17px',fontSize:11 ,width:200,mr:'1px'}}>
              { sdate }
            </Typography>
          </Box>
          <Typography variant={"h6"} sx={{ m: "10px" }}>
          発注明細確認一覧
          </Typography>
        </Grid>
        <Box className="dataGridList" style={gridStyle} sx={{ display: 'flex', justifyContent: 'flex-start',height: 450, width: '940px',padding:'0px',ml:'-70px',mt:'-30px' }}>
            <DataGrid
              sx={styles.grid}     // --> 不具合対応のためcssで罫線を引く
              rows={rows}
              columns={columns}
              //density='compact'//compact（狭め）、standard（標準）、comfortable（広め）
              initialState={{
                pagination: {
                  paginationModel: {
                    pageSize: 10,
                  },
                },
              }}
              pageSizeOptions={[10]}
              // checkboxSelection
              disableRowSelectionOnClick
              localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}  // メニュー日本語化
              showColumnRightBorder                                             // 列ヘッダセルの右側に線を引く
              showCellRightBorder                                               // セルの右側に線を引く
              getRowClassName={(params) => {
                if (params.row.DELFLG === '1') {
                  // 削除済明細にクラスを設定する
                  return 'row-deactive'
                }
                return '';
              }}
              rowSelectionModel={selectionModel}
              onRowSelectionModelChange={(RowIds) => {
                const diffRows = rows.filter(x =>RowIds.indexOf(x.id) != -1);
                const delRows = diffRows.filter(x => x.DELFLG == '1');
                if (delRows.length > 0) {
                  // 削除済明細の場合はクリックされた際に選択状態にしない
                  return;
                }
                setSelectionModel(RowIds);
              }}
              paginationModel={pagenationModel}
              onPaginationModelChange={(model) => {
                console.log(model);
                setPagenationModel(model);
              }}
              />
          </Box>
          <Box my={2} flexDirection="row" justifyContent="flex-end" display="flex">
            <Box ml={2}  sx={{ ml:'-70px',mt:'-20px' }}>
              <NumericFormat  //合計数量
                id={'SRYFOT'}
                inputRef={inSRYFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={1}
                fixedDecimalScale={true}            //小数点On/Off
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='合計数量'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch'}}                //textboxの幅
                value={totalSuryo}                  //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                // onKeyDown={(e) => onKeyDown(e.key,inDENH,inTSEBAKFOT)}
                onFocus={(e) => e.target.select()}
                />　
              <NumericFormat  //売価金額合計
                id={'BAKFOT'}
                inputRef={inBAKFOT}                   //項目名定義？
                thousandSeparator={','}
                decimalScale={0}
                fixedDecimalScale
                color='success'
                customInput={TextField}             //textfieldにreact-number-formatの機能を追加
                size='small'                        //textboxの全体サイズ
                label='合計金額'                         //textboxの左上に表示するラベル
                variant='outlined'                  //枠の表示方法
                InputLabelProps={{ shrink: true }}  //labelを表示左上に持ってくる？
                sx={{width: '17ch',mr:'0px'}}                 //textboxの幅
                value={totalBaika}               //初期値
                allowLeadingZeros                   //前ゼロ表示 on
                disabled={true}                    //使用可／不可
                //inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right'} }}  //補完,最大文字数,中央寄せ
                inputProps={{ autoComplete: 'off',maxLength: 11,style: {textAlign: 'right',touchAction: 'none'} }}  //補完,最大文字数,中央寄せ 2023/11/07 オンスクリーンキーボード対応
                // onKeyDown={(e) => onKeyDown(e.key,inTSEGNKFOT,inRET)}
                onFocus={(e) => e.target.select()}
                />
              </Box>
            </Box>

        <Box ml={2} flexDirection="row" justifyContent="flex-end" display="flex" marginTop={'5px'}>
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'250px'}}
              onClick={(e) => onClick_FooterButton_Back()}
              >
              {'戻る'}
            </Button>&nbsp;
          </Box >
          {/* //S SK-0080-001 2024/10/8           TCI)M.K【要望：発注明細確認一覧】 */}
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'50px'}}
              onClick={(e) => onClick_FooterButton_Print()}
              >
              {'印刷'}
            </Button>&nbsp;
          </Box >
          {/* //E SK-0080-001 */}
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'50px'}}
              onClick={(e) => onClick_FooterButton_OrderbyS()}
              disabled={disBtnOrderbyS}                    //使用可／不可
              >
              {'仕入先順'}
            </Button>&nbsp;
          </Box >
          <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'50px'}}
              onClick={(e) => onClick_FooterButton_OrderbyE()}
              disabled={disBtnOrderbyE}                    //使用可／不可
              >
              {'入力順'}
            </Button>&nbsp;
          </Box >
          {/* <Box >
            <Button
              variant='contained'
              color='success'
              sx={{width:100,mr:'5px'}}
              onClick={(e) => onClick_FooterButton_Search()}
              >
              {'検索(仮)'}
            </Button>
          </Box> */}
        </Box>
        {/* <p>{ state.usrnm }</p> */}
        <p><font color="red">{ alert }</font></p>
      </Paper>
    </Grid>
  );
};

export default TCSH0180;